import React from "react";
import ViewCompetition from "../../../components/Competicoes/ViewCompetition";
import Main from "../../../layout/Main";
import { Container } from "./styles";

export default function ViewComp() {
  return (
    <Main>
      <Container>
        <ViewCompetition />
      </Container>
    </Main>
  );
}
