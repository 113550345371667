import React from "react";

import { Container, Title, TextMuted, Card, Name, Phase, Row } from "./styles";
import { useHistory } from "react-router-dom";
import Loading from "../../ui/Loading";
import useApi from "../../../hooks/useApi";

export default function FinishedCompetitions() {
  const history = useHistory();
  const { data } = useApi("/competition/getAll");
  if (!data) {
    return <Loading />;
  }
  return (
    <Container>
      <Title>Classifique uma das competições abaixo!</Title>
      <TextMuted>
        As competições só terão sequência após a classificação manual de seus
        participantes.
      </TextMuted>
      <Row>
        {data.map((item) => {
          if (item?.actualPhase && item?.actualPhase < 4)
            return (
              <Card onClick={() => history.push("rank/" + item._id)}>
                <Name>{item.name}</Name>
                <Phase>Fase {item.actualPhase + 1} </Phase>
              </Card>
            );
        })}
      </Row>
    </Container>
  );
}
