import React from "react";
import Main from "../../layout/Main";
import HomeSection from "../../components/Main/HomeSection";
import AboutSection from "../../components/Main/AboutSection";
import RatingSection from "../../components/Main/RatingSection";
import InstructionsSection from "../../components/Main/InstructionsSection";

export default function Home() {
  return (
    <Main>
      <HomeSection />
      <InstructionsSection />
      <AboutSection />
      <RatingSection />
    </Main>
  );
}
