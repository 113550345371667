import styled from "styled-components";
import PrimaryButton from "../ui/PrimaryButton";
import PrimaryInput from "../ui/PrimaryInput";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: var(--radius-sm);
  width: 100%;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--white);
  margin-bottom: 20px;
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  margin-bottom: 40px;
  text-align: center;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  width: min(100%, 700px);
  &&:focus-within {
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
  }
`;
export const Input = styled(PrimaryInput)`
  color: var(--white);
  &&::placeholder {
    color: var(--white);
  }
`;
export const SubmitButton = styled(PrimaryButton)``;
