import styled from "styled-components";
import { MdClose } from "react-icons/md";
import PrimaryInput from "../../ui/PrimaryInput";
import PrimaryButton from "../../ui/PrimaryButton";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  padding: 15px 25px;
  background: var(--white);
  border-radius: var(--radius-sm);
  width: min(100%, 550px);
  z-index: 6;
  text-align: center;
  position: relative;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  margin-bottom: 10px;
`;
export const TextMuted = styled.div`
  margin: 20px;
  color: var(--primary);
  font-size: var(--font-sm);
  text-align: center;
`;
export const Link = styled.a`
  text-decoration: none;
  color: var(--secondary);
  font-size: var(--font-sm);
  margin: 10px 0;
  cursor: pointer;
  &:hover {
    color: var(--secondary-100);
  }
`;
export const Input = styled(PrimaryInput)`
  margin-top: 15px;
  color: var(--primary);
  &&::placeholder {
    color: var(--primary);
  }
`;
export const SubmitButton = styled(PrimaryButton)`
  margin-top: 10px;
`;
export const CloseIcon = styled(MdClose)`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 15px;
  width: 20px;
  height: 20px;
`;
