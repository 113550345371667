import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  Container,
  HomeImage,
  TextContainer,
  Row,
  Title,
  SpanLi,
  AltTitle,
} from "./styles";

export default function AboutUs() {
  const videoParentRef = useRef();

  // Autoplay video on Safari

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("chrome") > -1 && ua.indexOf("safari") < 0;
  };
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      console.log(player);
      if (player) {
        player.controls = true;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;
        const playVideo = async (player) => {
          await player
            .play()
            .then(() => {})
            .catch(() => {
              videoParentRef.current.style.display = "none";
            });
        };
        playVideo(player);
      }
    }
  }, []);
  return (
    <Container>
      <Row>
        {/* <HomeVideo autoPlay>
          <source src="/videos/aboutVideo.mp4" type="video/mp4" />
        </HomeVideo> */}
        <div
          style={{ maxWidth: "100%", height: "auto", marginTop: 60 }}
          className={"video"}
          ref={videoParentRef}
          dangerouslySetInnerHTML={{
            __html: `
          <video
            loop
            autoplay
            controls
            playsinline
            preload="metadata"
            style="width:100%; height: auto"
          >
          <source src="/videos/aboutVideo1.mp4" type="video/mp4" />
          </video>`,
          }}
        />

        <TextContainer>
          <AltTitle>o jogo</AltTitle>
          <SpanLi>
            <li className="li1">
              Leia atentamente as regras, para se inscrever nas competições;
            </li>
            <li>Inscreva-se na competição que mais combina com você;</li>
            <li>Abuse da criatividade ao fazer sua publicação;</li>
            <li>Os competidores serão divididos em grupos;</li>
            <li>
              A votação será aberta e os mais votados passarão para a próxima
              fase;
            </li>
            <li>
              Os 5 mais votados, disputarão o título de campeão e o prêmio da
              categoria;
            </li>
            <li>
              A plataforma entrará em contato com os vencedores para a
              premiação;
            </li>
            <br />
            <span>bônus master 5</span>
            <li>
              Quem vota também ganha;Premiação para a pessoa que mais votar na
              competição;
            </li>
            <li>Indique participantes e concorra a prêmios.</li>
          </SpanLi>
        </TextContainer>
        <HomeImage />
      </Row>
    </Container>
  );
}
