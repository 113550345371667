import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import PrimarySelect from "../../ui/PrimarySelect";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  border-radius: var(--radius-sm);
  

  @media (max-width: 600px) {
    width: calc(100% - 40px);
    padding-top: 10px;
    z-index: 1;
  }
`;

export const Title = styled.div`
  font-size: 3em;
  color: var(--white);
  text-align: center;
  z-index: 2;
  font-family: var(--title-font);

  @media (max-width: 800px){
    font-size: 2em;
    z-index: 2
  }
`;
export const Subtitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  color: #fff;
  margin-bottom: 45px;
  text-align: center;
  z-index: 2;
  @media (max-width: 800px){
    font-size: 20px;
    z-index: 2
  }
`;

export const TextMuted = styled.div`
  color: var(--white);
  font-size: var(--font-mute);
  z-index: 2;
`;

export const CategoriesList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  padding: 0 100px;
  z-index: 5;
  @media (max-width: 800px){
    padding: 0 30px;
  }
`;

export const ButtonsList = styled.div`
max-width: 80%;
width: 60%;
position: relative;
display: flex;
align-items: center;
justify-content: space-around;
overflow-y: hidden;
z-index: 5;
`;

export const List = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 99%;
  /* height: 230px; */
  ::-webkit-scrollbar {
    display: none;
  }

  padding: 0 10px;
`;

export const ListMessage = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  margin-bottom: 20px;
  margin: 0 auto;
  z-index: 2;
`;
export const ListTitle = styled.div`
  font-size: 2.2em;
  color: var(--white);
  font-family: var(--competition-font);
  margin-bottom: 10px;
  align-self: flex-start;
  z-index: 2;
`;
export const Card = styled.div`
  display: flex;
  min-width: 200px;
  min-height: 100px;
  background-image: url("images/logo-transparent.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--gray);
  border-radius: var(--radius-sm);
  z-index: 2;

  padding: 40px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    .card {
      display: flex;
    }
  }
`;
export const CardTitle = styled.div`
  z-index: 2;
  margin-bottom: 1em;
  font-size: var(--font-md);
  color: var(--white);
`;

export const CardContent = styled.div`
  z-index: 2;
  display: none;
  position: absolute;
  height: 182px;
  width: 282px;
  margin: 20px;
  background: linear-gradient(
    180deg,
    transparent 62%,
    rgba(255, 255, 255, 0.00345888) 63.94%,
    rgba(255, 255, 255, 0.014204) 65.89%,
    rgba(255, 255, 255, 0.0326639) 67.83%,
    rgba(255, 255, 255, 0.0589645) 69.78%,
    rgba(255, 255, 255, 0.0927099) 71.72%,
    rgba(255, 255, 255, 0.132754) 73.67%,
    rgba(255, 255, 255, 0.177076) 75.61%,
    rgba(255, 255, 255, 0.222924) 77.56%,
    rgba(255, 255, 255, 0.267246) 79.5%,
    rgba(255, 255, 255, 0.30729) 81.44%,
    rgba(255, 255, 255, 0.341035) 83.39%,
    rgba(255, 255, 255, 0.367336) 85.33%,
    rgba(255, 255, 255, 0.385796) 87.28%,
    rgba(255, 255, 255, 0.396541) 89.22%,
    rgba(255, 255, 255, 0.4) 91.17%
  );
  div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const DaysLeft = styled.span`
  position: absolute;
  top: 10px;
  right: 15px;
  color: var(--white);
  font-weight: 600;
  z-index: 2;
`;

export const FilterButton = styled.button`
  outline: none;
  border: unset;
  color: #cf368d;
  padding: 18px 15px;
  width: 100%;
  max-width: 210px;
  border-radius: 25px;
  text-transform: uppercase;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 2em;
  font-family: var(--button-font);
  font-size: 20px;
  z-index: 5;
`;
export const FilterButton2 = styled.button`
  outline: none;
  border: unset;
  color: #cf368d;
  padding: 18px 15px;
  width: 100%;
  max-width: 210px;
  border-radius: 25px;
  text-transform: uppercase;
  background: #008aab;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 2em;
  font-family: var(--button-font);
  font-size: 20px;
  z-index: 5;
`;
export const FilterButton3 = styled.button`
  outline: none;
  border: unset;
  color: #008aab;
  padding: 18px 15px;
  width: 100%;
  max-width: 210px;
  border-radius: 25px;
  text-transform: uppercase;
  background: #cf368d;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 2em;
  font-family: var(--button-font);
  font-size: 20px;
  z-index: 5;
`;

export const PreviousIcon = styled(IoIosArrowDropleft)`
  fill: var(--secondary);
  position: absolute;
  top: 95px;
  left: 0px;
  height: 40px;
  width: 50px;
  cursor: pointer;
  z-index: 5;
`;

export const NextIcon = styled(IoIosArrowDropright)`
  fill: var(--secondary);
  position: absolute;
  top: 95px;
  right: 0px;
  height: 40px;
  width: 50px;
  cursor: pointer;
  z-index: 5;
`;

export const Select = styled(PrimarySelect)`
  width: 100%;
  z-index: 5
  option {
    color: #000;
    text-align: center;
    background-color: var(--primary-600);
    font-size: 12px;
    z-index: 9
  }
  option:checked {
    background: unset;
    background: #000;
    color: var(--white);
    z-index: 9
  }
`;
export const SquaredTitle = styled.div`
  width: 75px;
  height: 75px;
  margin: 0 10px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_01.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
export const DivTitle = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const VetorImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 80vh;
  z-index: 1;
`;
export const DivButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5
`
