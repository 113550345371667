import React from "react";
import { useCountUp } from "react-countup";
import VizSensor from "react-visibility-sensor";
import styled from "styled-components";

const Counter = styled.div`
  font-size: var(--font-lg);
  color: var(--white);
`;
export default function AnimatedCount({
  end,
  duration,
  prefix = null,
  sufix = null,
}) {
  const { countUp, start, reset } = useCountUp({
    start: 0,
    end: end,
    useEasing: true,
    duration: duration,
  });
  return (
    <VizSensor
      onChange={(isVisible) => {
        if (isVisible) {
          start();
        } else {
          reset();
        }
      }}
    >
      <Counter>
        {prefix}
        {countUp}
        {sufix}
      </Counter>
    </VizSensor>
  );
}
