import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  Divide,
  Input,
  Select,
  Label,
  TextArea,
  FileInput,
  SubmitButton,
  Row,
  Column,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../services/api";
import useApi from "../../../hooks/useApi";
import Loading from "../../ui/Loading";
import NumberFormat from "react-number-format";
export default function DashCreate({ target }) {
  const [newData, setNewData] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [paidPhases, setPaidPhases] = useState([]);
  let apiPath;
  switch (target) {
    case "competições":
      apiPath = "/category/getAll";
      break;
    case "jurados":
      apiPath = "/competition/getAll";
      break;

    default:
      break;
  }
  const { data } = useApi(apiPath);

  const handleUnmask = (e) => {
    const { value } = e.target;
    const { name } = e.target;

    let finalValue = value.replace("R$ ", "");
    finalValue = finalValue.replace(".", "");
    finalValue = finalValue.replace(",", ".");
    setNewData((prevState) => ({
      ...prevState,
      [name]: parseFloat(finalValue),
    }));
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const file = e.target?.files;
    if (name === "dateEnd") {
      setNewData((prevState) => ({
        ...prevState,
        [name]: new Date(value).getTime() / 1000,
      }));
    } else if (file) {
      setNewData((prevState) => ({
        ...prevState,
        [name]: file[0],
      }));
    } else {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChecked = (e) => {
    if (e.target.checked) {
      paidPhases.push(e.target.value);
    } else {
      let index = paidPhases.indexOf(e.target.value);
      if (paidPhases.length === 1) {
        setPaidPhases([]);
      } else {
        paidPhases.splice(index, 1);
      }
    }
  };
  // Handle submit for 3 situations

  const handleSubmit = async () => {
    if (target === "categorias") {
      if (newData?.name) {
        try {
          const { data } = await api.post("category/new", {
            name: newData.name,
          });
          if (data.success) {
            toast.success(
              "Categoria " + newData.name + " cadastrada com sucesso"
            );
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao cadastrar");
        }
      } else {
        toast.error("Por favor, insira um nome!");
      }
    }
    if (target === "jurados") {
      if (
        newData?.name &&
        newData?.description &&
        newData?.competition_id &&
        newData?.image
      ) {
        let formData = new FormData();
        formData.append("name", newData.name);
        formData.append("description", newData.description);
        formData.append("competition_id", newData.competition_id);
        formData.append(
          "image",
          new File([newData.image], Date.now() + ".jpg", {
            type: "image/jpg",
          })
        );
        try {
          const { data } = await api.post("judge/new", formData);
          if (data.success) {
            toast.success("Juradx " + newData.name + " cadastradx com sucesso");
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao cadastrar");
        }
      } else {
        toast.error("Por favor, insira todos os dados!");
      }
    }
    if (target === "competições") {
      if (
        newData?.name &&
        newData?.description &&
        newData?.category_id &&
        newData?.content &&
        newData?.dateEnd
      ) {
        try {
          const { data } = await api.post("competition/new", {
            ...newData,
            paidPhases,
          });
          if (data.success) {
            toast.success(
              "Competição " + newData.name + " cadastrada com sucesso"
            );
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao cadastrar");
        }
      } else {
        toast.error("Por favor, insira todos os dados!");
      }
    }
  };

  const createSelect = () => {
    return data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));
  };

  if (target === "categorias") {
    return (
      <Container>
        <Title>Cadastrar {target}</Title>
        <Divide />
        <Input
          name="name"
          onChange={(e) => handleChange(e)}
          placeholder="Ex: Músicas"
        />
        <SubmitButton onClick={handleSubmit}>cadastrar</SubmitButton>
      </Container>
    );
  }
  if (target === "competições") {
    if (!data) {
      return <Loading />;
    }
    return (
      <Container>
        <Title>Cadastrar {target}</Title>
        <Divide />

        <Select
          name="category_id"
          onChange={(e) => handleChange(e)}
          defaultValue=""
        >
          <option value="" hidden disabled>
            Selecione uma categoria...
          </option>
          {createSelect()}
        </Select>
        <Row>
          <Column>
            <Label>Nome da competição</Label>

            <Input
              name="name"
              onChange={(e) => handleChange(e)}
              style={{ height: 26 }}
              placeholder="Ex: Música Country"
            />
          </Column>
          <Column>
            <Label>Data de término da primeira fase</Label>
            <Input
              name="dateEnd"
              onChange={(e) => handleChange(e)}
              type="date"
              placeholder="Ex: Músicas"
            />
          </Column>
        </Row>
        <Row>
          <Select
            name="content"
            onChange={(e) => handleChange(e)}
            defaultValue=""
          >
            <option value="" hidden disabled>
              Selecione o tipo da competição...
            </option>
            <option value="doc">PDF</option>
            <option value="image">Imagem</option>
            <option value="video">Vídeo</option>
          </Select>
          <Select
            name="isPaid"
            onChange={(e) => {
              e.target.value === "1" ? setIsPaid(true) : setIsPaid(false);
            }}
            defaultValue=""
          >
            <option value="" hidden disabled>
              A competição será paga?
            </option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </Select>
        </Row>
        {isPaid === true && (
          <>
            <NumberFormat
              customInput={Input}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              prefix="R$ "
              name="price"
              placeholder="Valor da taxa"
              onChange={(e) => handleUnmask(e)}
            />
            <Label>Fases em que será cobrada a taxa</Label>
            <Row>
              <Row style={{ justifyContent: "center" }}>
                <Label>
                  <span>Inscrição</span>
                </Label>
                <Input
                  type="checkbox"
                  value="start"
                  onChange={(e) => {
                    handleChecked(e);
                  }}
                />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Label>
                  <span>Semifinal</span>
                </Label>
                <Input
                  type="checkbox"
                  value="3"
                  onChange={(e) => {
                    handleChecked(e);
                  }}
                />
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Label>
                  <span>Final</span>
                </Label>
                <Input
                  type="checkbox"
                  value="4"
                  onChange={(e) => {
                    handleChecked(e);
                  }}
                />
              </Row>
            </Row>
          </>
        )}
        <TextArea
          name="description"
          onChange={(e) => handleChange(e)}
          placeholder="Insira uma descrição desta competição"
        />
        <SubmitButton onClick={handleSubmit}>cadastrar</SubmitButton>
      </Container>
    );
  }
  if (target === "jurados") {
    if (!data) {
      return <Loading />;
    }
    return (
      <Container>
        <Title>Cadastrar {target}</Title>
        <Divide />
        <Select
          defaultValue=""
          name="competition_id"
          onChange={(e) => handleChange(e)}
        >
          <option value="" hidden disabled>
            Selecione uma competição para vincular o jurado...
          </option>
          {createSelect()}
        </Select>
        <Label style={{ alignSelf: "flex-start", fontWeight: "bold" }}>
          Imagem do Jurado: (somente .png .jpg ou .jpeg)
        </Label>
        <FileInput
          name="image"
          accept=".jpg,.png,.jpeg"
          type="file"
          onChange={(e) => handleChange(e)}
        />
        <Input
          name="name"
          onChange={(e) => handleChange(e)}
          placeholder="Ex: John Doe"
        />
        <TextArea
          name="description"
          onChange={(e) => handleChange(e)}
          placeholder="Descrição das competências do jurado"
        />
        <SubmitButton onClick={handleSubmit}>cadastrar</SubmitButton>
      </Container>
    );
  }
}
