import React from "react";

// Icons
import {
  FaTachometerAlt,
  FaUserCog,
  FaSearch,
  FaHome,
  FaUser,
  FaChess,
} from "react-icons/fa";
import { RiGameFill } from "react-icons/ri";
import { BiChevronRight, BiTargetLock } from "react-icons/bi";
import { GiTrophyCup } from "react-icons/gi";
import { AiFillFileExcel } from "react-icons/ai";
import { BsFillBriefcaseFill } from "react-icons/bs";

// Pages
import Dashboard from "../pages/Dashboard";
import Categorias from "../pages/Dashboard/Categorias";
import Jurados from "../pages/Dashboard/Jurados";
import Competicoes from "../pages/Dashboard/Competicoes";
import TabelaUsuarios from "../pages/Dashboard/TabelaUsuarios";
import TabelaJurados from "../pages/Dashboard/TabelaJurados";
import Classificar from "../pages/Dashboard/Classificar";
import Ranking from "../pages/Dashboard/Ranking";
import Relatorios from "../pages/Dashboard/Relatorios";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Buscas from "../pages/Buscas";
import ViewProfile from "../pages/ViewProfile";
import UserCategories from "../pages/Competicoes/UserCategories";
import UserComps from "../pages/Competicoes/UserComps";
import JoinComp from "../pages/Competicoes/JoinComp";
import ViewComp from "../pages/Competicoes/ViewComp";
import IniciarCompeticao from "../pages/Dashboard/IniciarCompeticao";
import Pagamentos from "../pages/Pagamentos";
import Sobre from "../pages/Sobre";
import Termos from "../pages/Termos";
import RecuperarSenha from "../pages/RecuperarSenha";

const routing = {
  admin: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <FaTachometerAlt />,

      component: Dashboard,
    },
    {
      title: "Editar",
      icon: <RiGameFill />,
      dropdown: [
        {
          title: "Categorias",
          path: "/dashboard/categorias",
          icon: <BiChevronRight />,
          component: Categorias,
        },
        {
          title: "Competições",
          path: "/dashboard/competicoes",
          icon: <BiChevronRight />,
          component: Competicoes,
        },
        {
          title: "Jurados",
          path: "/dashboard/jurados",
          icon: <BiChevronRight />,
          component: Jurados,
        },
      ],
    },
    {
      title: "Usuários",
      icon: <FaUserCog />,
      dropdown: [
        {
          title: "Tabela de Jurados",
          icon: <BiChevronRight />,
          component: TabelaJurados,
          path: "/dashboard/tabela-jurados",
        },
        {
          title: "Tabela de Usuários",
          icon: <BiChevronRight />,
          component: TabelaUsuarios,
          path: "/dashboard/tabela-usuarios",
        },
      ],
    },
    {
      title: "Competições",
      icon: <GiTrophyCup />,
      dropdown: [
        {
          title: "Iniciar",
          icon: <BiChevronRight />,
          component: IniciarCompeticao,
          path: "/dashboard/iniciar",
        },
        {
          title: "Classificar",
          icon: <BiChevronRight />,
          component: Classificar,
          path: "/dashboard/classificar",
        },
      ],
    },
    {
      title: "Relatórios",
      icon: <AiFillFileExcel />,
      component: Relatorios,
      path: "/dashboard/relatorios",
    },
    {
      component: Ranking,
      path: "/dashboard/rank/:competition_id",
      hidden: true,
    },
  ],

  public: [
    {
      title: "Home",
      path: "/",
      component: Home,
    },
    {
      path: "/home",
      icon: "",
      component: Home,
      hidden: true,
    },
    {
      title: "O Jogo",
      path: "/sobre-nos",
      component: Sobre,
    },

    {
      title: "Competições",
      path: "/competicoes",
      component: UserCategories,
    },

    {
      path: "/competicoes/:id",
      component: UserComps,
      hidden: true,
    },
    {
      path: "/competicao/:id",
      component: ViewComp,
      hidden: true,
    },
    {
      path: "/participar/:id",
      component: JoinComp,
      hidden: true,
    },
    // {
    //   title: "Buscar",
    //   path: "/buscar",
    //   icon: <BiTargetLock />,
    //   component: Buscas,
    // },
    {
      title: "Perfil",
      path: "/perfil",
      icon: <FaUser />,
      user: true,
      hidden: !localStorage.getItem("@Master5/id") && true,
      component: Profile,
    },
    {
      path: "/user/:id",
      hidden: true,
      component: ViewProfile,
    },
    {
      path: "/pagamento/:id",
      hidden: true,
      component: Pagamentos,
    },
    {
      path: "/termos-de-uso",
      hidden: true,
      component: Termos,
    },
    {
      path: "/recuperar-senha",
      hidden: true,
      component: RecuperarSenha,
    },
  ],
};
export default routing;
