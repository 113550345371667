export const TOKEN_KEY = "@Master5/token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("@Master5/id");
  localStorage.removeItem("@Master5/region");
  localStorage.removeItem("@Master5/username");
  localStorage.removeItem("@Master5/email");
  localStorage.removeItem("@Master5/access");
};
