import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  // dev
  // baseURL: "http://162.214.54.8:3346",
  baseURL: "https://master-5-game.herokuapp.com/",
  // local
  //baseURL: "http://127.0.0.1:3333",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export const apiPath = api.defaults.baseURL;

export default api;
