import React from "react";
import {
  Container,
  CloseIcon,
  ModalBody,
  ModalTitle,
  ModalText,
  ModalButtons,
  ReturnButton,
  ConfirmButton,
} from "./styles";

export default function Modal({ title, text, confirmFunction, setOpen,data }) {
  return (
    <Container>
      <ModalBody>
        <CloseIcon onClick={() => setOpen(false)} />
        <ModalTitle>{title}</ModalTitle>
        <hr />
        <ModalText>{text}</ModalText>
        <hr />
        <ModalButtons>
          <ReturnButton onClick={() => setOpen(false)}>Não</ReturnButton>
          <ConfirmButton
            onClick={() => {
              confirmFunction();
              setOpen(false);
            }}
          >
            Sim
          </ConfirmButton>
        </ModalButtons>
      </ModalBody>
    </Container>
  );
}
