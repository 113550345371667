import React from "react";
import { Container, Header, Logo, Body, Footer, TextMuted } from "./styles";
import routing from "../../../Routes/routing";
import Divider from "../../ui/Divider";
import NavLink from "../NavLink";
export default function Sidebar() {
  return (
    <Container>
      <Header>
        <Logo src="/images/logo 1.png" alt="Logo" />
      </Header>
      <Divider />
      <Body>
        {routing.admin.map(
          (route) =>
            !route?.hidden && (
              <NavLink
                title={route.title}
                icon={route.icon}
                dropdown={route?.dropdown}
                path={route.path}
              />
            )
        )}
      </Body>
      <Footer>
        <TextMuted>Proudly developed by Mind Consulting</TextMuted>
      </Footer>
    </Container>
  );
}
