import styled from "styled-components";

const PrimarySelect = styled.select`
  border: unset;
  border-bottom: 2px solid #fff;
  background: #1b1a1a;
  margin-bottom: 2em;
  padding: 10px;
  color: var(--white);
  font-size: var(--font-md);
  width: calc(100% - 20px);
  border: 2px solid #fff;
  border-radius: var(--radius-sm);
  color: #fff;
  width: 500px;
  border: 2px solid #ff;
  border-radius: var(--radius-sm);
  color: #fff;
  @media (max-width: 800px) {
    max-width: 372px;
  }
  &&:focus {
    border: 1px solid var(--secondary-100);
  }

  &&:hover {
    border: 1px solid var(--secondary-100);
  }

  &&::placeholder {
    color: var(--white);
  }
`;
export default PrimarySelect;
