import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-image: url("../../../images/01_bg.jpg");
  background-size: cover;
  background-position: bottom;
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
`;
export const Title = styled.div`
  font-size: 3rem;
  color: var(--white);
  font-family: var(--title-font);
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;
export const Row = styled.div`
  width: 80%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100% !important;
    padding: unset;
  }
  z-index: 2;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    width: 100%;
    &.customCol {
      width: 40%;
    }
    &.numberCol {
      width: 20%;
    }
  }
  @media (max-width: 800px) {
    &.customCol {
      padding: 0 10px;
    }
    &.numberCol {
      padding: 0 10px;
    }
  }
`;
export const Card = styled.div`
  display: flex;
  height: 120px;
  align-items: flex-start;
  &.first {
  }
  &.second {
  }
  &.third {
    margin-top: 40px;
  }
  &.fourth {
    margin-bottom: 55px;
  }
  &.last {
    margin-top: 10px;
  }

  @media (max-width: 800px) {
    &.first {
      height: max-content;
      margin-bottom: 25px;
    }
    &.second {
      height: max-content;
      margin-bottom: 25px;
    }
    &.third {
      height: max-content;
      margin-bottom: 25px;
      margin-top: 0px;
    }
    &.fourth {
      height: max-content;
      margin-bottom: 25px;
    }
    &.last {
      height: max-content;
      margin-bottom: 25px;
      margin-top: 0px;
    }
  }
`;
export const SquaredNumber1 = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_02.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0 15px 0px 0px;
  }
`;
export const SquaredNumber2 = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_03.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0 15px 0px 0px;
  }
`;
export const SquaredNumber3 = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_04.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0 15px 0px 0px;
  }
`;
export const SquaredNumber4 = styled.div`
  width: 60px;
  margin: 0 15px 0 0;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_05.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0 15px 0px 0px;
  }
`;
export const SquaredNumber5 = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_01.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 00px) {
    margin: 0 15px 0px 0px;
  }
`;
export const SquaredTitle = styled.div`
  width: 75px;
  height: 75px;
  margin: 0 10px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_01.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    width: 65px;
    height: 65px;
    margin: 0px 30px 0 0px;
  }
`;
export const Number = styled.div`
  color: var(--white);
  font-size: var(--font-md);
`;
export const CardTitle = styled.div`
  font-weight: bold;
  line-height: 30px;
  font-size: var(--font-md);
  font-family: var(--text-font);
  color: var(--white);
  margin-bottom: 5px;
  width: 100%;
  &.customTxt1 {
    width: 250px;
  }
`;
export const CardDescription = styled.div`
  font-family: var(--text-font);
  font-weight: bold;
  font-size: 18px;
  color: var(--secondary);
  margin-right: auto;
`;
export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  margin: 20px 0 20px 0;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const VerticalLine = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
  height: 450px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
`;
export const HomeImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: initial;
  width: 100%;
  height: 80vh;
  z-index: 1;
`;

export const SquaredDiv = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  z-index: 2;
`;

export const Span = styled.span`
  color: #008aab;
  font-family: var(--text-font);
  font-weight: bold;
  font-size: 18px;
`;

export const Span2 = styled.span`
  color: #cf368d;
  font-family: var(--title-font);
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
`;
