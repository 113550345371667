import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`;
export const RankingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--secondary-100);

  padding: 10px;
`;

const rowCss = css`
  flex: 1;
  text-align: center;
  > strong {
    font-size: var(--font-md);
  }
`;

export const Rank = styled.div`
  ${rowCss}
`;
export const Name = styled.div`
  ${rowCss}
`;
export const Title = styled.div`
  ${rowCss}
`;
export const Votes = styled.div`
  ${rowCss}
`;

export const Checkbox = styled.input`
  background: unset;
  border: 1px solid black;
  margin: 10px 5px 0 5px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  align-self: center;
  cursor: pointer;
`;
