import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { toast } from "react-toastify";
export const UserRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && localStorage.getItem("@Master5/access") === "1" ? (
        <Component {...props} />
      ) : (
        <>
          {toast.error(
            "Oops, você precista estar logado para acessar esta página!"
          )}
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        </>
      )
    }
  />
);
export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && localStorage.getItem("@Master5/access") === "999" ? (
        <Component {...props} />
      ) : (
        <>
          {toast.error("Oops, você não está permitido a acessar esta página!")}
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        </>
      )
    }
  />
);
