import React, { useEffect, useState } from "react";
import Admin from "../../../layout/Admin";

import { columns, tableIcons } from "./tableConfig";
import { Container } from "./styles";
import MaterialTable from "material-table";
import useApi from "../../../hooks/useApi";
import Loading from "../../../components/ui/Loading";
import { BsTrash } from "react-icons/bs";
import Modal from "../../../components/ui/Modal";
import api from "../../../services/api";
import { toast } from "react-toastify";

export default function TabelaUsuarios() {
  const [shownData, setShownData] = useState([]);
  const { data } = useApi("/users/getAll");
  const [users, setUsers] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [modal, setModal] = useState(false);
  const actions = [
    (rowData) => {
      return {
        icon: BsTrash,
        tooltip: "Excluir Usuário",
        onClick: (event, rowData) => {
          setModal(true);
          setClicked({ email: rowData.email });
        },
      };
    },
  ];

  const handleDelete = async () => {
    try {
      const { data } = await api.get(`users/delete/${clicked.email}`);
      if (data.success) {
        toast.success("Usuário removido com sucesso!");
        window.location.reload();
      } else {
        toast.error("Oops, houve um erro ao excluir este usuário!");
      }
    } catch (error) {
      toast.error("Oops, houve um erro ao excluir este usuário!");
    }
  };

  if (data && !users) {
    setUsers(data);
  }
  useEffect(() => {
    if (users?.length) {
      let newData = [];
      users.map((user) => {
        newData.push({
          name: user.name,
          email: user.email,
          indicatedBy: user?.indicatedBy ? user.indicatedBy : "---",
          region: user.region,
        });
      });
      setShownData(newData);
    }
  }, [users]);

  if (shownData.length === 0) {
    return <Loading />;
  }
  return (
    <Admin>
      <Container>
        <MaterialTable
          title="Usuários Cadastrados"
          icons={tableIcons}
          localization={{
            header: { actions: "" },

            body: {
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
            pagination: {
              labelRowsSelect: "linhas",
              labelDisplayedRows: "{count} de {from}-{to}",
              firstTooltip: "Primeira página",
              previousTooltip: "Página anterior",
              nextTooltip: "Próxima página",
              lastTooltip: "Última página",
            },
            toolbar: {
              exportTitle: "Exportar",
              exportAriaLabel: "Exportar",
              searchTooltip: "Pesquisar",
              searchPlaceholder: "Pesquisar",
            },
          }}
          options={{
            search: true,
            paging: true,
            filtering: false,
            exportButton: true,
            exportDelimiter: ";",
            actionsColumnIndex: -1,
          }}
          style={{
            width: "100%",
          }}
          actions={actions}
          columns={columns}
          data={shownData}
        />
      </Container>
      {modal && (
        <Modal
          title="Esse processo não poderá ser desfeito!"
          text="Você deseja realmente excluir este usuário? Esse procedimento acarretará no bloqueio total deste ao acesso da plataforma!"
          setOpen={setModal}
          confirmFunction={() => handleDelete(clicked)}
          data={clicked}
        />
      )}
    </Admin>
  );
}
