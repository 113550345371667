import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimaryInput from "../../ui/PrimaryInput";
import PrimarySelect from "../../ui/PrimarySelect";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Form = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 20px;
  color: var(--white);
  padding: 20px;
`;

export const Label = styled.div`
  font-size: var(--font-sm);
  color: var(--white);
  font-weight: bold;
  align-self: flex-start;
  margin-bottom: 5px;
`;

export const Input = styled(PrimaryInput)`
  border: unset;
  border-bottom: 2px solid #fff;
  background: transparent;
  margin-bottom: 1em;
  padding: 10px;
  color: var(--white);
  font-size: var(--font-md);
  border: 2px solid #fff;
  border-radius: var(--radius-sm);
  color: #fff;
  width: calc(100% - 20px);
  border: 2px solid #ff;
  border-radius: var(--radius-sm);
  color: #fff;

  // &::placeholder {
  //   color: var(--white);
  // }
  // &[type="date"] {
  //   -webkit-appearance: none;
  // }
  // &[type="date"]::-webkit-inner-spin-button,
  // &[type="date"]::-webkit-calendar-picker-indicator {
  //   display: none;
  //   -webkit-appearance: none;
  // }
  @media (max-width: 800px) {
    width: 350px;
  }
`;

export const Select = styled(PrimarySelect)`
  color: var(--white);
  background-color: transparent;
  &::placeholder {
    color: var(--white);
  }
  option {
    color: var(--primary);
  }
  @media (max-width: 800px) {
    width: 372px;
  }
`;

export const Button = styled(PrimaryButton)``;
