import React from "react";
import { useState } from "react";
import useApi from "../../../hooks/useApi";
import Loading from "../../ui/Loading";
import { CSVLink } from "react-csv";
import { Container, Title, Body, Select, ConfirmButton } from "./styles";

export default function Report() {
  const [selected, setSelected] = useState(null);
  const [phase, setPhase] = useState(null);
  const { data } = useApi("tournament/getFinished");

  const headers = [
    { label: "Nome", key: "playerName" },
    { label: "Votos", key: "votes" },
  ];

  const createSelect = () => {
    return data.map((tournament, index) => (
      <option key={tournament._id} value={index}>
        {tournament.name}
      </option>
    ));
  };

  function handleChange(index) {
    let array = [];
    if (phase) {
      if (phase < 3) {
        Object.values(data[index].phase[phase][0]).map((group) => {
          group.map((player) => {
            array.push(player);
          });
        });
        setSelected(array);
      } else {
        setSelected(data[index].phase[phase][0]);
      }
    }
  }
  if (!data) {
    return <Loading />;
  }
  if (data.length === 0) {
    return <Title>Nenhuma competição finalizada até o momento...</Title>;
  }
  return (
    <Container>
      <Title>Relatório de Votos</Title>
      <Select
        defaultValue=""
        onChange={(e) => {
          setPhase(e.target.value);
        }}
      >
        <option hidden disabled value="" key="">
          Selecione uma Fase...
        </option>
        <option value="0" key="0">
          Primeira Fase
        </option>
        <option value="1" key="1">
          Segunda Fase
        </option>
        <option value="2" key="2">
          Semifinal
        </option>
        <option value="3" key="3">
          Final
        </option>
      </Select>
      {phase && (
        <Select
          variant="outlined"
          fullWidth
          required
          displayEmpty
          defaultValue=""
          color="primary"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          className="mb-4"
        >
          <option disabled hidden value="">
            Selecione uma Competição...
          </option>
          {createSelect()}
        </Select>
      )}
      {selected && (
        <CSVLink data={selected} headers={headers} separator={";"}>
          <ConfirmButton>Baixar .CSV</ConfirmButton>
        </CSVLink>
      )}
    </Container>
  );
}
