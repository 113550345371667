import styled, { keyframes } from "styled-components";
import { shake } from "../../../layout/Animations";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimarySelect from "../../ui/PrimarySelect";
import RoundedImage from "../../ui/RoundedImage";

export const Container = styled.div`
  width: 80%;
  background: transparent;
  border-radius: var(--radius-sm);

  padding: 20px;
  margin-bottom: 20px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--white);
`;

export const Text = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  text-align: center;
  margin-top: 10px;
`;

export const Select = styled(PrimarySelect)`
  color: var(--white);
  &::placeholder {
    color: var(--primary);
  }
  flex: unset;
  width: 400px;
  margin: unset;
  & option {
    color: var(--primary);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
`;

export const TextSmall = styled.div`
  font-size: var(--font-sm);
  color: var(--white);
  margin-top: 15px;
`;

export const Image = styled(RoundedImage)``;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: var(--primary);

  border-radius: var(--radius-sm);
  width: min(100%, 160px);
  padding: 30px;
  margin: 5px 10px;
  &:hover {
    animation: ${shake} 1s ease-in-out 0s;
  }
`;
export const CardTitle = styled.div`
  margin-bottom: 1em;
  font-size: var(--font-md);
  color: var(--white);
`;
export const CardDescription = styled.p`
  color: var(--white);
  font-size: var(--font-sm);

  text-align: justify;
`;
export const CardButton = styled(PrimaryButton)`
  align-self: center;
  justify-self: flex-end;
  margin: unset;
  padding: 15px;
`;
