import React, { useState } from "react";
import { Container, TabName, VerticalLine, Select } from "./styles";

export default function Tabs({ setComponent, tabs = null }) {
  const [clicked, setClicked] = useState(1);

  const clickHandler = (click) => {
    setClicked(click);
    setComponent(click);
  };

  const responsiveTab = () => {
    if (window.innerWidth > 600) {
      return tabs.map((tab, index) => (
        <>
          <TabName
            className={"active"}
            onClick={() => clickHandler(index + 1)}
            style={{ backgroundColor: tab.backgroundColor, color: tab.color }}
          >
            {tab.name}
          </TabName>
          {index !== tabs.length - 1}
        </>
      ));
    } else {
      return (
        <Select onChange={(e) => clickHandler(parseInt(e.target.value) + 1)}>
          {tabs.map((tab, index) => (
            <option value={index} className={clicked === index + 1 && "active"}>
              {tab.name}
            </option>
          ))}
        </Select>
      );
    }
  };

  return <Container>{responsiveTab()}</Container>;
}
