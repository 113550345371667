import React from "react";

import { Container, Body, Content } from "./styles";
import Sidebar from "../../components/Dashboard/Sidebar";
import Topbar from "../../components/Dashboard/Topbar";

export default function Admin({ children }) {
  return (
    <Container>
      <Sidebar />
      <Body>
        <Topbar title={"Dashboard"} />
        <Content>{children}</Content>
      </Body>
    </Container>
  );
}
