import styled from "styled-components";
import { animated as a } from "react-spring";
export const Container = styled.div`
  width: 225px;
  height: 300px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: absolute;
    width: 250px;
    height: 250px;
    cursor: pointer;

    will-change: transform, opacity;
    background-size: cover;
  }

  @media (max-width: 600px) {
    display: inline-block;
    > div {
      position: unset;
    }
  }
`;
export const CardFront = styled(a.div)`
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const CardBack = styled(a.div)`
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 2em;
  color: var(--white);
  font-family: var(--competition-font);
`;
export const Subtitle = styled.div`
  font-size: var(--font-md);
  color: var(--white);
`;
export const Description = styled.div`
  padding: 15px 0 15px 0;
  font-size: var(--font-md);
  color: var(--white);
`;
