import styled from "styled-components";

export const Container = styled.div`
  padding: 150px 0px 20px 0px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../images/bg_competicoes.jpg");
  background-size: cover;
  @media (max-width: 600px) {
    padding-top: 30px;
  }
`;
export const VetorImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 80vh;
  z-index: 1;
`;

