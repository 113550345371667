import styled, { keyframes } from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";

// Animation

const shake = keyframes`

0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

export const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  width: 80%;
`;

export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--white);
  padding: 20px;
`;
export const Card = styled.div`
  display: flex;
  min-width: 200px;
  min-height: 100px;
  background-image: url("images/logo-transparent.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--gray);
  border-radius: var(--radius-sm);

  padding: 40px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    .card {
      display: flex;
    }
  }
`;
export const CardTitle = styled.div`
  margin-bottom: 1em;
  font-size: var(--font-md);
  color: var(--white);
`;
export const CardDescription = styled.p`
  color: var(--white);
  font-size: var(--font-sm);

  text-align: justify;
`;
export const CardButton = styled(PrimaryButton)`
  align-self: center;
  justify-self: flex-end;
  margin: unset;
  padding: 15px;
`;
export const CardContent = styled.div`
  display: none;
  position: absolute;
  height: 182px;
  width: 282px;
  margin: 20px;
  background: linear-gradient(
    180deg,
    transparent 62%,
    rgba(255, 255, 255, 0.00345888) 63.94%,
    rgba(255, 255, 255, 0.014204) 65.89%,
    rgba(255, 255, 255, 0.0326639) 67.83%,
    rgba(255, 255, 255, 0.0589645) 69.78%,
    rgba(255, 255, 255, 0.0927099) 71.72%,
    rgba(255, 255, 255, 0.132754) 73.67%,
    rgba(255, 255, 255, 0.177076) 75.61%,
    rgba(255, 255, 255, 0.222924) 77.56%,
    rgba(255, 255, 255, 0.267246) 79.5%,
    rgba(255, 255, 255, 0.30729) 81.44%,
    rgba(255, 255, 255, 0.341035) 83.39%,
    rgba(255, 255, 255, 0.367336) 85.33%,
    rgba(255, 255, 255, 0.385796) 87.28%,
    rgba(255, 255, 255, 0.396541) 89.22%,
    rgba(255, 255, 255, 0.4) 91.17%
  );
  div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const CardText = styled.span`
  position: absolute;
  bottom: 10px;
  text-align: center;
  left: 0;
  right: 0;
  color: var(--primary);
  font-weight: bold;
`;

export const Text = styled.div`
  font-size: var(--font-sm);
  color: var(--white);
  padding: 20px;
`;
