import React, { useState } from "react";
import {
  Container,
  Body,
  Title,
  TextMuted,
  Link,
  Input,
  SubmitButton,
  CloseIcon,
} from "./styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";

export default function LoginModal({ setOpen }) {
  const [newData, setNewData] = useState(null);
  const history = useHistory();

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (newData?.email && newData?.password) {
      try {
        const { data } = await api.post("logger/login", { ...newData });
        if (data.success) {
          localStorage.setItem("@Master5/token", data.token);
          localStorage.setItem("@Master5/id", data.user._id);
          localStorage.setItem("@Master5/access", data.user.access);
          localStorage.setItem("@Master5/name", `${data.user.name}`);
          localStorage.setItem("@Master5/email", `${data.user.email}`);
          if (localStorage.getItem("@Master5/access") === "999") {
            history.push("/dashboard");
          } else {
            history.go(0);
          }
        }
      } catch (error) {
        toast.error("Login ou senha inválidos!");
      }
    } else {
      toast.error("Por favor, insira todos os dados!");
    }
  };

  return (
    <Container>
      <Body>
        <CloseIcon onClick={() => setOpen(false)} />
        <Title>Seja bem vindo!</Title>

        <Input
          type="email"
          placeholder="Insira seu e-mail"
          name="email"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <Input
          type="password"
          placeholder="Insira sua senha"
          name="password"
          onChange={(e) => {
            handleChange(e);
          }}
        />

        <SubmitButton onClick={handleLogin}>Entrar!</SubmitButton>

        <TextMuted>
          Não possui conta? {"    "}
          <Link
            onClick={() => {
              setOpen({ register: true });
            }}
          >
            Cadastre-se aqui!
          </Link>
        </TextMuted>
        <Link onClick={() => setOpen({ password: true })}>
          Esqueceu sua senha?
        </Link>
      </Body>
    </Container>
  );
}
