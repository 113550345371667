import React, { useEffect, useState } from "react";
import JoinCompetition from "../../../components/Competicoes/JoinCompetition";
import Main from "../../../layout/Main";
import Login from "../../Login";
import { Container } from "./styles";

export default function JoinComp() {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    !open && window.location.reload();
  }, [open]);

  const conditionalRendering = () => {
    if (localStorage.getItem("@Master5/id")) {
      return <JoinCompetition />;
    } else {
      console.log("salve");
      return <Login setOpen={setOpen} open={open} />;
    }
  };

  return (
    <Main>
      <Container>{conditionalRendering()}</Container>
    </Main>
  );
}
