import styled from "styled-components";
import { fadeInOut } from "../../../layout/Animations";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 997;
  background-image: url("../../../images/01_bg.jpg");

  background-size: cover;
`;

export const Image = styled.div`
  position: absolute;
  background-image: url("../../../images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 100vh;
  z-index: 998;
`;

export const Logo = styled.img`
  // animation: ${fadeInOut} 1s ease-in-out infinite;
  width: 50%;
  height: auto;
  height: auto;
  z-index: 999;
  @media (max-width: 800px){
    width: 70%
  }
`;
