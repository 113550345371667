import React from "react";
import { Container } from "./styles";
import Admin from "../../layout/Admin";
export default function Dashboard() {
  return (
    <Admin>
      <Container> </Container>
    </Admin>
  );
}
