import React, { useState } from "react";
import {
  Container,
  Body,
  Title,
  Input,
  SubmitButton,
  CloseIcon,
  Select,
  Col,
  Row,
} from "./styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import RegionOptions from "../../ui/RegionOptions";

export default function SignUpModal({ setOpen }) {
  const [newData, setNewData] = useState(null);
  const [valid, setValid] = useState(false);
  const handleEmail = async () => {
    if (newData?.email) {
      const { email } = newData;
      if (email.split("@").length > 1) {
        try {
          const { data } = await api.get("/users/getByEmail/" + newData.email);
          if (data === "") {
            setValid(true);
          } else {
            toast.error("E-mail já cadastrado! Esqueceu sua senha?");
          }
        } catch (error) {
          toast.error(
            "Oops, houve um erro no nosso servidor, tente novamente!"
          );
        }
      } else {
        toast.error("Insira um email válido!");
      }
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      newData?.email &&
      newData?.password &&
      newData?.confirmPassword &&
      newData?.name &&
      newData?.region
    ) {
      const {
        email,
        password,
        confirmPassword,
        name,
        indicatedBy,
        confirmEmail,
        region,
      } = newData;
      if (password === confirmPassword && email === confirmEmail) {
        try {
          const { data } = await api.post("/users/new", {
            email,
            password,
            name,
            indicatedBy,
            region,
          });
          if (data.success) {
            localStorage.setItem("@Master5/token", data.token);
            localStorage.setItem("@Master5/id", data.user._id);
            localStorage.setItem("@Master5/access", data.user.access);
            localStorage.setItem("@Master5/name", `${data.user.name}`);
            localStorage.setItem("@Master5/region", `${data.user?.region}`);
            localStorage.setItem("@Master5/email", `${data.user.email}`);
            toast.success(`Seja bem vindx ${name}!`);
            window.location.reload();
          }
        } catch (error) {
          toast.error(
            "Oops, houve um erro em nosso servidor, por favor tente novamente!"
          );
        }
      } else {
        toast.error("Há divergência nos campos!");
      }
    } else {
      toast.error("Preencha todos os campos para continuar!");
    }
  };

  return (
    <Container>
      <Body>
        <CloseIcon onClick={() => setOpen(false)} />
        <Title>Cadastre-se agora mesmo!</Title>
        <Row>
          <Col>
            <Input
              type="text"
              placeholder="Insira seu nome completo"
              name="name"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <Input
              type="email"
              placeholder="Insira seu e-mail"
              onBlur={handleEmail}
              name="email"
              onChange={(e) => {
                handleChange(e);
                setValid(false);
              }}
            />
            <Input
              type="email"
              placeholder="Confirme seu e-mail"
              name="confirmEmail"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Col>
          <Col>
            <Input
              type="password"
              placeholder="Insira sua senha"
              name="password"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <Input
              type="password"
              placeholder="Confirme sua senha"
              name="confirmPassword"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <Input
              type="text"
              placeholder="Insira o email de quem indicou"
              name="indicatedBy"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Col>
        </Row>
        <Select
          onChange={(e) => handleChange(e)}
          name="region"
          defaultValue={null}
        >
          <option value={null}>Selecione um Estado</option>
          <RegionOptions />
        </Select>
        <SubmitButton onClick={handleSubmit} disabled={!valid}>
          Cadastrar!
        </SubmitButton>
      </Body>
    </Container>
  );
}
