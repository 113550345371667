import React, { useState } from "react";
import Admin from "../../../layout/Admin";
import Tabs from "../../../components/ui/Tabs";
import DashCreate from "../../../components/Dashboard/DashCreate";
import DashEdit from "../../../components/Dashboard/DashEdit";
import DashDelete from "../../../components/Dashboard/DashDelete";

import { Container } from "./styles";

export default function Categorias() {
  const [component, setComponent] = useState(1);
  return (
    <Admin>
      <Tabs
        setComponent={setComponent}
        tabs={[{ name: "Criar" }, { name: "Editar" }, { name: "Deletar" }]}
      />
      <Container>
        {component === 1 && <DashCreate target="categorias" />}
        {component === 2 && <DashEdit target="categorias" />}
        {component === 3 && <DashDelete target="categorias" />}
      </Container>
    </Admin>
  );
}
