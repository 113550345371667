import React from "react";
import Report from "../../../components/Dashboard/Report";
import Admin from "../../../layout/Admin";

export default function Relatorios() {
  return (
    <Admin>
      <Report />
    </Admin>
  );
}
