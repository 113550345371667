import styled from "styled-components";
import RoundedImage from "../../ui/RoundedImage";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    width: 350px;
  }
`;

export const Text = styled.div`
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
  text-transform: uppercase;
  font-family: var(--title-font);
`;
export const HorizontalLine = styled.div`
  margin-top: 15px;
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const ProfileImage = styled(RoundedImage)`
  background: #000;
`;
