import React, { useState, useEffect } from "react";
import {
  Container,
  Title,
  Divide,
  Input,
  Select,
  Label,
  TextArea,
  SubmitButton,
  FileInput,
} from "./styles";
import useApi from "../../../hooks/useApi";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../ui/Loading";

export default function DashEdit({ target }) {
  const [newData, setNewData] = useState(null);
  const [selected, setSelected] = useState(null);
  let apiPath;
  switch (target) {
    case "categorias":
      apiPath = "/category/getAll";
      break;
    case "competições":
      apiPath = "/competition/getAll";
      break;
    case "jurados":
      apiPath = "/judge/getAll";
      break;
    default:
      break;
  }
  const { data } = useApi(apiPath);

  const createSelect = () => {
    return data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));
  };
  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const file = e.target?.files;
    if (file) {
      setNewData((prevState) => ({
        ...prevState,
        [name]: file[0],
      }));
    } else {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectId = (e) => {
    setSelected(null);
    const { name, value } = e.target;
    let competition;
    data.map((item) => {
      if (item._id === value) {
        competition = item;
      }
    });
    setTimeout(() => {
      setSelected(competition);
    }, 1);
    setNewData(() => ({
      [name]: value,
      name: competition.name,
      description: competition.description,
    }));
  };

  const handleSubmit = async () => {
    if (target === "categorias") {
      if (newData?.name && newData?.category_id) {
        const { category_id, ...body } = newData;
        try {
          const { data } = await api.post("category/edit/" + category_id, {
            ...body,
          });
          if (data.success) {
            toast.success(
              "Categoria " + newData.name + " alterada com sucesso!"
            );
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao editar");
        }
      } else {
        toast.error("Por favor, preencha todos os campos!");
      }
    }
    if (target === "jurados") {
      if (newData?.name && newData?.description) {
        const { judge_id } = newData;
        let formData = new FormData();
        formData.append("name", newData.name);
        formData.append("description", newData.description);
        if (newData?.image) {
          formData.append(
            "image",
            new File([newData.image], Date.now() + ".jpg", {
              type: "image/jpg",
            })
          );
        }
        try {
          const { data } = await api.post("judge/edit/" + judge_id, formData);
          if (data.success) {
            toast.success("Juradx " + newData.name + " editadx com sucesso");
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao editar");
        }
      } else {
        toast.error("Por favor, insira todos os dados!");
      }
    }
    if (target === "competições") {
      if (newData?.name && newData?.description) {
        const { competition_id, ...body } = newData;

        try {
          const { data } = await api.post(
            "competition/edit/" + competition_id,
            {
              ...body,
            }
          );
          if (data.success) {
            toast.success(
              "Competição " + newData.name + " editada com sucesso"
            );
          }
        } catch (error) {
          toast.error("Oops, houve algum erro ao editar");
        }
      } else {
        toast.error("Por favor, insira todos os dados!");
      }
    }
  };

  if (!data) {
    return <Loading />;
  }
  if (target === "categorias") {
    return (
      <Container>
        <Title>Editar {target}</Title>
        <Divide />
        <Select
          name="category_id"
          onChange={(e) => handleChange(e)}
          defaultValue=""
        >
          <option disabled hidden value="">
            Selecione...
          </option>
          {createSelect()}
        </Select>
        <Input
          name="name"
          onChange={(e) => handleChange(e)}
          placeholder="Ex: Músicas"
        />
        <SubmitButton onClick={handleSubmit}>Editar</SubmitButton>
      </Container>
    );
  }
  if (target === "competições") {
    return (
      <Container>
        <Title>Editar {target}</Title>
        <Divide />

        <Select
          name="competition_id"
          onChange={(e) => handleSelectId(e)}
          defaultValue=""
        >
          <option value="" hidden disabled>
            Selecione uma Competição...
          </option>
          {createSelect()}
        </Select>
        {selected && (
          <>
            <Label style={{ alignSelf: "flex-start" }}>
              Nome da competição
            </Label>

            <Input
              defaultValue={selected.name}
              placeholder="Ex: Música Country"
              name="name"
              onChange={(e) => handleChange(e)}
            />

            <TextArea
              placeholder="Insira uma descrição desta competição"
              name="description"
              onChange={(e) => handleChange(e)}
            >
              {selected.description}
            </TextArea>
            <SubmitButton onClick={handleSubmit}>cadastrar</SubmitButton>
          </>
        )}
      </Container>
    );
  }
  if (target === "jurados") {
    return (
      <Container>
        <Title>Editar {target}</Title>
        <Divide />
        <Select
          name="judge_id"
          onChange={(e) => handleSelectId(e)}
          defaultValue=""
        >
          <option value="" hidden disabled>
            Selecione um jurado para editar...
          </option>
          {createSelect()}
        </Select>
        {selected && (
          <>
            <Label style={{ alignSelf: "flex-start", fontWeight: "bold" }}>
              Imagem do Jurado: (somente .png .jpg ou .jpeg)
            </Label>
            <FileInput
              name="image"
              accept=".jpg,.png,.jpeg"
              type="file"
              onChange={(e) => handleChange(e)}
            />
            <Input
              name="name"
              onChange={(e) => handleChange(e)}
              placeholder="Ex: John Doe"
              defaultValue={selected.name}
            />
            <TextArea
              name="description"
              onChange={(e) => handleChange(e)}
              placeholder="Descrição das competências do jurado"
              defaultValue={selected.description}
            />
            <SubmitButton onClick={handleSubmit}>editar</SubmitButton>
          </>
        )}
      </Container>
    );
  }
}
