import styled from "styled-components";

export const Container = styled.div`
  padding: 120px 0 20px 0;
  width: 100vw;
  min-height: calc(100vh - 140px);
  background: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat:
`;
