import React from "react";
import Routes from "./Routes";
import GlobalStyles from "./styles/GlobalStyles";
import "react-toastify/dist/ReactToastify.css";
import "./styles/fonts.css";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Routes />
        <ToastContainer />
        <GlobalStyles />
      </div>
    </Provider>
  );
}

export default App;
