import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

export const Title = styled.div`
  font-size: var(--font-lg);
  margin-bottom: 5px;
`;

export const TextMuted = styled.div`
  font-size: var(--font-sm);
  margin-bottom: 10px;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 20px;
`;
export const Card = styled.div`
  width: 25%;
  padding: 20px;
  text-align: center;
  border: 2px solid var(--secondary);
  margin-bottom: 20px;
  background: var(--primary);
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.5);
  }
`;
export const Name = styled.div`
  color: var(--white);
  font-size: var(--font-md);
`;
export const Phase = styled.div`
  color: var(--white);
  font-size: var(--font-sm);
`;
