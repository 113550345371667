import styled from "styled-components";

export const Container = styled.div`
  padding: 120px 15px 20px 15px;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: var(--primary-700);
  background-image: url("../../../images/bg_perfil.jpg");
  background-size: cover;
  z-index: 2;
  @media (max-width: 600px) {
    padding-top: 30px;

  }
`;

export const Card = styled.div`
  background: transparent;
  border-radius: var(--radius-sm);
  width: min(80vw, 100%);
  margin-top: 20px;
  padding: 20px 15px;
  z-index: 2;
  @media (max-width: 800px) {
    width: 350px;
  }
`;

export const HomeImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 0;
  height: 0;
  z-index: 1;
  @media (max-width: 800px) {
  }
`;
