import React, { useState } from "react";
import {
  Container,
  Title,
  Divide,
  Input,
  SubmitButton,
  Select,
} from "./styles";
import useApi from "../../../hooks/useApi";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../ui/Loading";
import Modal from "../../ui/Modal";
export default function DashDelete({ target }) {
  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState(null);
  let apiPath;
  switch (target) {
    case "categorias":
      apiPath = "/category/getAll";
      break;
    case "competições":
      apiPath = "/competition/getAll";
      break;
    case "jurados":
      apiPath = "/judge/getAll";
      break;
    default:
      break;
  }
  const { data } = useApi(apiPath);
  const createSelect = () => {
    return data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (newData?.id) {
      switch (target) {
        case "categorias":
          try {
            const { data } = await api.post("category/delete/" + newData.id);
            if (data.success) {
              toast.success("Categoria deletada com sucesso!");
            }
          } catch (error) {
            toast.error("Oops, houve um erro ao deletar!");
          }
          break;
        case "competições":
          try {
            const { data } = await api.post("competition/delete/" + newData.id);
            if (data.success) {
              toast.success("Competição deletada com sucesso!");
            }
          } catch (error) {
            toast.error("Oops, houve um erro ao deletar!");
          }
          break;
        case "jurados":
          try {
            const { data } = await api.post("judge/delete/" + newData.id);
            if (data.success) {
              toast.success("Juradx deletadx com sucesso!");
            }
          } catch (error) {
            toast.error("Oops, houve um erro ao deletar!");
          }
          break;
        default:
          break;
      }
    } else {
      toast.error("Por favor, selecione um valor para deletar!");
    }
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <>
      <Container>
        <Title>Deletar {target}</Title>
        <Divide />
        <Select name="id" onChange={(e) => handleChange(e)} defaultValue="">
          <option value="" disabled hidden>
            Selecione para deletar...
          </option>
          {createSelect()}
        </Select>
        <SubmitButton onClick={() => setOpen(true)}>deletar</SubmitButton>
      </Container>
      {open && (
        <Modal
          title={"Deseja continuar com a exclusão?"}
          text={`Operações como essa não poderão ser desfeitas! Caso esteja deletando uma categoria, todas as suas competições ficarão inacessíveis, havendo necessidade em excluí-las também! O mesmo vale para a competição e seus participantes.`}
          confirmFunction={handleSubmit}
          setOpen={setOpen}
        />
      )}
    </>
  );
}
