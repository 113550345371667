import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimaryFileInput from "../../ui/PrimaryFileInput";
import PrimaryInput from "../../ui/PrimaryInput";
import PrimaryTextArea from "../../ui/PrimaryTextArea";

export const Container = styled.div`
  width: 80%;
  background: transparent;
  border-radius: var(--radius-sm);
  margin-top: 30px;
  padding: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--white);
  margin-bottom: 10px;
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  margin-bottom: 40px;
  text-align: center;
`;
export const HorizontalLine = styled.div`
  margin: 15px 0;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.6);
`;
export const TextMd = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  margin-bottom: 10px;
  text-align: center;
`;
export const Body = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Input = styled(PrimaryInput)`
  width: min(100%, 450px);
`;
export const TextArea = styled(PrimaryTextArea)`
  width: min(100%, 450px);
`;
export const FileInput = styled(PrimaryFileInput)`
  width: min(100%, 450px);
  color: var(--white);
`;
export const SubmitButton = styled(PrimaryButton)`
  margin-top: 1.5em;
`;
