import styled from "styled-components";
import { shake } from "../../../layout/Animations";
import PrimaryButton from "../../ui/PrimaryButton";
import RoundedImage from "../../ui/RoundedImage";
import { animated as a } from "react-spring";
import PrimarySelect from "../../ui/PrimarySelect";
import PrimaryInput from "../../ui/PrimaryInput";

export const Container = styled.div`
  width: 80%;
  background: transparent;
  border-radius: var(--radius-sm);
  margin-top: 30px;
  padding: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 10px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 3em;
  color: var(--white);
  font-family: var(--title-font);
  margin-bottom: 10px;
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: var(--font-md);
  color: var(--secondary);
  margin-bottom: 40px;
  text-align: center;
`;
export const JudgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
  width: 50%;
  padding: 20px;
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  -webkit-box-shadow: 5px 5px 20px 5px var(--dark);
  box-shadow: 5px 5px 20px 5px var(--dark);
  @media (max-width: 600px) {
    width: unset;
  }
`;
export const JudgeText = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  text-align: center;
`;
export const Image = styled(RoundedImage)`
  /* &.resize {
    > div {
    }
  } */

  background-size: 100px auto;
  width: 100px;
  height: 100px;
`;

export const RankingList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  padding: 15px;
  margin: 10px 0;
`;
export const RankingColumn = styled.div`
  position: relative;
  padding: 55px 0 10px 0;
  max-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;

  h2 {
    font-size: 13px;
    color: var(--white);
    text-align: center;
    margin: unset;
    padding: 5px;
  }
`;

export const RankingImage = styled.div`
  background-size: 80px auto;
  background-repeat: no-repeat;
  background-color: var(--light-gray);
  height: 80px;
  width: 80px;
  background-position: center;
  border-radius: var(--radius-circle);
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  margin-bottom: 10px;
  .hovered {
    display: none;
  }
  &:hover {
    .hovered {
      display: block;
    }
  }
`;
export const Backdrop = styled.div`
  border-radius: var(--radius-circle);
  height: 80px;
  width: 80px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
`;

export const Text = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  text-align: center;
  margin-bottom: 30px;
`;

export const JudgeDescription = styled.div`
  width: 60%;
  font-size: var(--font-sm);
  color: var(--white);
  font-style: italic;
  margin-left: 15px;
  @media (max-width: 600px) {
    width: unset;
  }
`;
export const PlayersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

export const JoinButton = styled(PrimaryButton)`
  margin: 10px auto;
  padding: 12px;
`;

export const HorizontalLine = styled.div`
  margin: 15px 0;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.6);
`;

export const Crown = styled.img`
  width: 50px;
  height: auto;
  position: absolute;
  top: -45px;
  left: 15px;
  z-index: 3;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Select = styled(PrimarySelect)`
  color: var(--white);
  &::placeholder {
    color: var(--primary);
  }
  flex: unset;
  max-width: 400px;
  margin: unset;
  & option {
    color: var(--white);
  }
`;

export const Input = styled(PrimaryInput)`
  max-width: 380px;
`;
