import React from "react";
import { useHistory } from "react-router-dom";
import AnimatedCount from "../../ui/AnimatedCount";
import {
  Container,
  DarkContainer,
  Title,
  Subtitle,
  Ratings,
  Card,
  UsersIcon,
  MoneyIcon,
  TextHolder,
  Button,
  Col,
  HorizontalLine,
  QuoteCard,
  QuotesIcon,
  Quote,
  QuoteWritter,
  Span,
  Span2,
  SquaredFooter,
  HomeImage,
} from "./styles";
export default function RatingSection() {
  const history = useHistory();
  return (
    <Container>
      <HomeImage />
      <TextHolder>
        <Title>NOSSA MISSÃO</Title>
        <Quote>
          A <Span2> m5 </Span2> é uma plataforma de competições online, das mais
          variadas categorias. <br /> Nossa missão é{" "}
          <Span>
            gerar visibilidade e oportunidade aos grandes talentos de cada parte
            do brasil.
          </Span>
        </Quote>
        <img src="../../../images/icon_01.png" />
      </TextHolder>
    </Container>
  );
}
