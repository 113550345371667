import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import { GiConsoleController } from "react-icons/gi";
const padding = "20px";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  /* flex: 1; */

  background: transparent;
  transition: all ease-in-out 0.3s;
  padding: ${padding} 0;
  z-index: 10;
  @media (min-width: 800px) {
    &.scrolled {
      img {
        height: 50px;
      }
      padding: 0;
      height: 80px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @media (max-width: 800px) {
    img {
      height: 50px;
    }
    flex-direction: column;
    padding: 0;
    height: 80px;
    background-color: var(--primary-600);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const LogoHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  @media (max-width: 800px) {
    width: 90%;
    justify-content: flex-start;
  }
`;
export const Logo = styled.img`
  height: 75px;
  width: auto;
  margin-left: 3rem;
  cursor: pointer;
`;
export const Nav = styled.div`
  padding-right: 3rem;

  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    display: none;

    &.open {
      position: absolute;
      top: 80px;
      width: calc(100% - 3rem);
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: var(--primary-600);
    }
    transition: all 105ms ease-in-out;
    -webkit-transition: all 105ms ease-in-out;
  }
`;

export const Link = styled.div`
  font-size: 13px;
  font-family: var(--button-font);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  padding: 16px 10px;
  margin: 0 15px;
  cursor: pointer;

  > svg {
    margin-right: 7px;
  }

  &&:hover {
    color: var(--secondary);
    /* border-radius: var(--radius-sm); */
  }
  transition: all ease-in-out 0.2s;
`;

export const LoginIcon = styled(GiConsoleController)`
  width: 18px;
  height: 18px;
`;

export const LogoutIcon = styled(FaSignOutAlt)`
  width: 18px;
  height: 18px;
`;

export const MenuIcon = styled(FaBars)`
  display: none;
  width: 18px;
  height: 18px;
  @media (max-width: 800px) {
    fill: var(--white);
    display: inline;
  }
`;
