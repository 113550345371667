import styled from "styled-components";

const PrimaryButton = styled.button`
  outline: none;
  border: unset;
  color: var(--white);
  padding: 16px 15px;
  width: 100%;
  max-width: 270px;
  border-radius: 15px;
  text-transform: uppercase;
  background: var(--secondary);
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 2em;
  font-family: var(--button-font);
  font-size: 17px;
  &:hover {
    background: var(--secondary-100);
  }
  &:disabled {
    background: var(--gray);
  }
`;
export default PrimaryButton;
