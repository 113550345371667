import styled from "styled-components";
import PrimaryButton from "../../components/ui/PrimaryButton";
import PrimaryInput from "../../components/ui/PrimaryInput";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 120px;
  @media (max-width: 600px) {
    margin-top: unset;
  }
`;
export const Form = styled.div`
  width: min(100%, 350px);
  text-align: center;
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  border-radius: var(--radius-sm);
  padding: 30px;
`;

export const Title = styled.div`
  font-family: var(--title-font);
  font-size: 2.5em;
  color: var(--white);
  margin-bottom: 1em;
`;
export const Subtitle = styled.div`
  font-family: var(--button-font);
  font-size: 1.3em;
  color: var(--white);
  margin-bottom: 1em;
`;

export const TokenInput = styled(PrimaryInput)`
  text-transform: uppercase;
  border: 1px solid var(--secondary-100);
  font-size: 2.2em;
  text-align: center;
  font-family: var(--title-font);

  &::placeholder {
    text-align: center;
    letter-spacing: 7px;
    font-size: var(--font-lg);
  }
`;
export const Input = styled(PrimaryInput)``;

export const Button = styled(PrimaryButton)``;
