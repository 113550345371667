import React from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../hooks/useApi";

import Loading from "../../ui/Loading";
import {
  Container,
  Header,
  TextHolder,
  Title,
  Subtitle,
  StartButton,
  Card,
  CardTitle,
  HomeImage,
  DivAbout,
  Description,
} from "./styles";

export default function AboutSection() {
  const { data } = useApi("/competition/getAll");

  let cardNumber =
    window.innerWidth > 400 && window.innerWidth < 800
      ? 2
      : window.innerWidth >= 800
      ? 3
      : 1;
  const history = useHistory();
  const generateCards = (amount) => {
    if (data.length > 0) {
      let previous = null;
      let render = [];
      for (let i = 0; i < amount; i++) {
        if (i < data.length) {
          let index = Math.floor(Math.random() * data.length);
          while (index === previous) {
            index = Math.floor(Math.random() * data.length);
          }
          previous = index;
          render.push({
            name: data[index].name,
            description:
              data[index]?.description?.length > 120
                ? data[index]?.description.substr(0, 119) + "..."
                : data[index]?.description,
            key: data[index]._id,
            path: `/competicoes/${data[index]._id}`,
          });
        }
      }
      return render.map((competition) => (
        <Card
          key={competition.key}
          onClick={() => {
            history.push(competition.path);
          }}
        >
          <CardTitle>{competition.name}</CardTitle>
        </Card>
      ));
    } else {
      return <Title>Nenhuma competição cadastrada!</Title>;
    }
  };
  if (!data) {
    return <Loading />;
  }
  return (
    <Container>
      {/* <Header>
        <Title>VOTAÇÕES ROLANDO</Title>
      </Header> */}
      <DivAbout>
        <TextHolder>{generateCards(cardNumber)}</TextHolder>
        <Subtitle>
          Acesse as competições que estão rolando agora e vote nos melhores
          talentos de cada uma! Ajude a escolher um Master 5
        </Subtitle>
        <StartButton onClick={() => history.push("/competicoes")}>
          Ver mais Competições!
        </StartButton>
      </DivAbout>
      <HomeImage />
    </Container>
  );
}
