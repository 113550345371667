import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import api from "../../services/api";
import LoginModal from "../Login/LoginModal";
import Loading from "../ui/Loading";
import {
  Container,
  Header,
  Title,
  Body,
  Subtitle,
  Input,
  SubmitButton,
} from "./styles";

export default function Payment() {
  const { id } = useParams();
  const [cardData, setCardData] = useState({
    email: localStorage.getItem("@Master5/email"),
    user_id: localStorage.getItem("@Master5/id"),
    competition_id: id,
  });
  const [open, setOpen] = useState(!localStorage.getItem("@Master5/token"));
  const [paid, setPaid] = useState(false);
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const history = useHistory();

  const getUser = async () => {
    try {
      const { data: userData } = await api.get(
        `users/get/${localStorage.getItem("@Master5/id")}`
      );
      setUser(userData);
    } catch (error) {
    }
  };
  const getCompetition = async () => {
    const { data: compData } = await api.get(`competition/get/${id}`);
    setData(compData);
  };
  useEffect(() => {
    window.Mercadopago.setPublishableKey(
      "APP_USR-3d9508dd-dedc-4bf2-b955-96e47a7c98d8"
    );
    getCompetition();
    if (!open) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (data && user?.paidCompetitions) {
      user.paidCompetitions.map((competition) => {
        if (competition.competition_id === id) {
          competition.phases.map((phase) => {
            if (phase === data.actualPhase.toString()) {
              setPaid(true);
            }
          });
        }
      });
    }
  }, [data, user]);

  async function guessPaymentMethod() {
    if (cardData?.cardNumber) {
      let bin = cardData.cardNumber.substring(0, 6);
      await window.Mercadopago.getPaymentMethod(
        {
          bin: bin,
        },
        (status, response) => {
          if (status === 200) {
            let paymentMethod = response[0];
            setCardData((prevState) => ({
              ...prevState,
              paymentMethodId: paymentMethod.id,
            }));
          } else {
            toast.error("Dados de cartão inválidos");
          }
        }
      );
    }
  }

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name === "date") {
      let data = value.split("/");
      setCardData((prevState) => ({
        ...prevState,
        cardExpirationMonth: data[0],
        cardExpirationYear: data[1],
      }));
    } else if (name === "cardNumber") {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value.replaceAll(" ", ""),
      }));
    } else {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePayment = async () => {
    let $form = document.getElementById("paymentForm");
    window.Mercadopago.createToken($form, async (status, response) => {
      if (status === 200 || status === 201) {
        try {
          const { data: resp } = await api.post("payment/checkout", {
            ...cardData,
            transactionAmount: data.price,
            token: response.id,
            phase: data.actualPhase,
          });
          if (resp.success) {
            toast.success("Pagamento processado com sucesso!");
            // history.push("/");
          } else {
            toast.error("Houve um erro ao processar seu pagamento");
          }
        } catch (error) {
          toast.error("Oops, seu cartão foi recusado");
        }
      } else {
        toast.error("Por favor, verifique seus dados!");
      }
    });
  };

  if (!data) {
    return <Loading />;
  } else if (data?.paidPhases) {
    let i = 0;
    data.paidPhases.map((phase) => {
      if (phase === data.actualPhase.toString()) {
        i = 1;
      }
    });
    if (i === 0) {
      history.push("/");
    }
  }
  if (paid) {
    return (
      <Container>
        <Body>
          <Title>Você já pagou esta competição!</Title>
        </Body>
      </Container>
    );
  }

  return (
    <Container>
      {open && <LoginModal setOpen={setOpen} />}

      <Body id="paymentForm">
        <Title>Título</Title>
        <Subtitle>Subtitulo</Subtitle>
        <Input
          placeholder="Nome como está no cartão"
          onChange={(e) => handleChange(e)}
          name="cardholderName"
          id="cardholderName"
          data-checkout="cardholderName"
        />
        <NumberFormat
          customInput={Input}
          format={"###.###.###-##"}
          name="docNumber"
          id="docNumber"
          data-checkout="docNumber"
          placeholder="CPF"
          onChange={(e) => handleChange(e)}
        />
        <NumberFormat
          customInput={Input}
          allowNegative={false}
          name="cardNumber"
          id="cardNumber"
          format={"#### #### #### ####"}
          data-checkout="cardNumber"
          placeholder="Número do cartão"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={guessPaymentMethod}
        />
        <NumberFormat
          customInput={Input}
          format={"##/##"}
          name="date"
          placeholder="Validade do cartão"
          onChange={(e) => handleChange(e)}
        />
        <NumberFormat
          customInput={Input}
          name="securityCode"
          id="securityCode"
          data-checkout="securityCode"
          format={"###"}
          placeholder="CVV"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="text"
          value={cardData?.cardExpirationMonth}
          id="cardExpirationMonth"
          name="cardExpirationMonth"
          data-checkout="cardExpirationMonth"
          hidden
        />
        <input
          type="text"
          value={cardData?.cardExpirationYear}
          id="cardExpirationYear"
          name="cardExpirationYear"
          data-checkout="cardExpirationYear"
          hidden
        />
        <input
          hidden
          id="issuer"
          name="issuer"
          data-checkout="issuer"
          value={cardData?.issuer_id}
        />
        <input
          hidden
          id="docType"
          name="docType"
          data-checkout="docType"
          value={"CPF"}
        />

        <SubmitButton onClick={handlePayment}>Realizar Pagamento</SubmitButton>
      </Body>
    </Container>
  );
}
