import React from "react";
import Payment from "../../components/Payment";
import Main from "../../layout/Main";
import { Container } from "./styles";

export default function Pagamentos() {
  return (
    <Main>
      <Container>
        <Payment />
      </Container>
    </Main>
  );
}
