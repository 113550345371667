import styled from "styled-components";
import PrimaryInput from "../../components/ui/PrimaryInput";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
`;

export const Input = styled(PrimaryInput)`
  margin-top: 30px;
  width: min(100%, 400px);
  border: 1px solid var(--secondary);
  border-radius: var(--radius-sm);
`;

export const SearchHolder = styled.div`
  width: min(100%, 800px);
  background: var(--white);
  border-radius: var(--radius-sm);
`;
