import styled from "styled-components";
import { MdClose } from "react-icons/md";
import PrimaryInput from "../../ui/PrimaryInput";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimarySelect from "../../ui/PrimarySelect";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Body = styled.div`
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  position: relative;
  width: max(400px, 60%);
  height: 80vh;
  border-radius: var(--radius-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  -webkit-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  font-family: var(--title-font);

  color: var(--white);
  margin-bottom: 10px;
`;
export const Input = styled(PrimaryInput)`
  margin-top: 15px;
  width: 85%;
  @media (max-width: 800px) {
    &&::placeholder {
      font-size: var(--font-sm);
      width: 90%;
    }
    margin-bottom: 5px;
  }
`;
export const SubmitButton = styled(PrimaryButton)`
  margin: 10px 0 10px 0;
`;
export const CloseIcon = styled(MdClose)`
  position: absolute;
  cursor: pointer;
  color: var(--white);
  top: 10px;
  right: 15px;
  width: 20px;
  height: 20px;
`;
export const Col = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
export const Row = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Select = styled(PrimarySelect)`
  width: 50%;
  color: var(--white);
  flex: none;
  &::placeholder {
    color: var(--white);
  }
  option {
    color: var(--primary);
  }
  @media (max-width: 600px) {
    width: 90%;
    margin-top: 10px;
    max-height: 40px;
    font-size: var(--font-sm);
    &&::placeholder {
      font-size: var(--font-sm);
    }
  }
`;
