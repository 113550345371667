import styled from "styled-components";
import { animated as a } from "react-spring";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 2%;
`;

export const Card = styled.div`
  width: 225px;
  height: 300px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    position: absolute;
    width: 225px;
    height: 300px;
    cursor: pointer;
    will-change: transform, opacity;
    background-size: cover;
  }
`;
export const CardBody = styled(a.div)`
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  font-size: 2em;
  color: var(--white);
  font-family: var(--competition-font);
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  text-align: center;
`;
export const Description = styled.div`
  padding: 15px 0 15px 0;
  font-size: var(--font-md);
  color: var(--white);
  text-align: center;
`;

export const Text = styled.div`
  font-size: var(--font-md);
  color: var(--white);
  padding: 5px;
`;
