import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSpring } from "react-spring";
import {
  Container,
  CardFront,
  CardBack,
  Title,
  Subtitle,
  Description,
} from "./styles";

export default function FlipCard({ name, numPlayers = 0, description, path }) {
  const history = useHistory();
  const [flipped, set] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  return (
    <Container
      onMouseEnter={() => set((state) => !state)}
      onMouseLeave={() => set((state) => !state)}
      onClick={() => history.push(path)}
    >
      <CardFront
        style={{ opacity: opacity.interpolate((o) => 1 - o), transform }}
      >
        <Title>{name}</Title>
      </CardFront>
      <CardBack
        style={{
          opacity,
          transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
        }}
      >
        <Subtitle>{numPlayers} participantes</Subtitle>
        <Description>{description}</Description>
      </CardBack>
    </Container>
  );
}
