import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import { apiPath } from "../../../services/api";
import CompetitionCard from "../../ui/CompetitionCard";
import Loading from "../../ui/Loading";
import RegionOptions from "../../ui/RegionOptions";
import {
  Container,
  Title,
  Subtitle,
  JoinButton,
  JudgeContainer,
  Text,
  JudgeDescription,
  JudgeText,
  PlayersList,
  RankingList,
  RankingColumn,
  RankingImage,
  Backdrop,
  Crown,
  Row,
  Col,
  Select,
  Input,
} from "./styles";

export default function Competition() {
  const [filter, setFilter] = useState("all");
  const [name, setName] = useState("");

  let { id } = useParams();
  const { data } = useApi(`/competition/get/${id}`);
  const { data: voters } = useApi(`/users/voters/${id}`);

  const history = useHistory();

  useEffect(() => {
    
  }, [filter, name]);

  function handlePlayers() {
    if (data?.actualPhase) {
      if (data.actualPhase === 3) {
        return data.phase[data.actualPhase][0].map((player) => {
          if (filter !== "all" && name !== "") {
            if (
              filter === player?.region &&
              player?.name.toLowerCase().includes(name.toLowerCase())
            ) {
              return (
                <CompetitionCard
                  player={player}
                  type={data.content}
                  competition_id={data._id}
                />
              );
            }
          } else if (filter !== "all" && name === "") {
            if (filter === player?.region) {
              return (
                <CompetitionCard
                  player={player}
                  type={data.content}
                  competition_id={data._id}
                />
              );
            }
          } else if (filter === "all" && name !== "") {
            if (player?.name.toLowerCase().includes(name.toLowerCase())) {
              return (
                <CompetitionCard
                  player={player}
                  type={data.content}
                  competition_id={data._id}
                />
              );
            }
          } else {
            return (
              <CompetitionCard
                player={player}
                type={data.content}
                competition_id={data._id}
              />
            );
          }
        });
      } else {
        return Object.entries(data.phase[data.actualPhase][0]).map(
          ([id, group]) => {
            return group.map((player) => {
              if (filter !== "all" && name !== "") {
                if (
                  filter === player?.region &&
                  player?.name.toLowerCase().includes(name.toLowerCase())
                ) {
                  return (
                    <CompetitionCard
                      player={player}
                      type={data.content}
                      competition_id={data._id}
                    />
                  );
                }
              } else if (filter !== "all" && name === "") {
                console.log(filter === player?.region);
                if (filter === player?.region) {
                  return (
                    <CompetitionCard
                      player={player}
                      type={data.content}
                      competition_id={data._id}
                    />
                  );
                }
              } else if (filter === "all" && name !== "") {
                if (player?.name.toLowerCase().includes(name.toLowerCase())) {
                  return (
                    <CompetitionCard
                      player={player}
                      type={data.content}
                      competition_id={data._id}
                    />
                  );
                }
              } else {
                return (
                  <CompetitionCard
                    player={player}
                    type={data.content}
                    competition_id={data._id}
                  />
                );
              }
            });
          }
        );
      }
    } else {
      return data.players.map((player) => {
        if (filter !== "all" && name !== "") {
          console.log("Ambos");
          console.log(filter === player?.region);
          console.log(filter, player?.region);
          if (
            filter === player?.region &&
            player?.name.toLowerCase().includes(name.toLowerCase())
          ) {
            return (
              <CompetitionCard
                player={player}
                type={data.content}
                competition_id={data._id}
              />
            );
          }
        } else if (filter !== "all" && name === "") {
          console.log("Região");
          console.log(player)
          if (filter === player?.region) {
            console.log(player.region)
            return (
              <CompetitionCard
                player={player}
                type={data.content}
                competition_id={data._id}
              />
            );
          }
        } else if (filter === "all" && name !== "") {
          console.log("Nome");

          if (player?.name.toLowerCase().includes(name.toLowerCase())) {
            return (
              <CompetitionCard
                player={player}
                type={data.content}
                competition_id={data._id}
              />
            );
          }
        } else {
          console.log("Nenhum");

          return (
            <CompetitionCard
              player={player}
              type={data.content}
              competition_id={data._id}
            />
          );
        }
      });
    }
  }

  if (!data || !voters) {
    return <Loading />;
  }

  return (
    <Container>
      <Title>{data?.name}</Title>
      <Text>{data?.numPlayers && `(${data.numPlayers} participantes)`}</Text>
      <Subtitle>{data?.description}</Subtitle>
      {data.actualPhase === undefined && (
        <JoinButton onClick={() => history.push(`/participar/${id}`)}>
          Quero participar!
        </JoinButton>
      )}
      <Row>
        {data?.judge && (
          <JudgeContainer>
            <Col>
              <RankingImage
                style={{
                  backgroundImage: `url(${apiPath + data.judge.imgPath})`,
                  cursor: "pointer",
                  width: 120,
                  height: 120,
                  backgroundSize: "120px, auto",
                }}
              >
                <Backdrop
                  className="hovered"
                  style={{ width: 120, height: 120 }}
                />
              </RankingImage>
              <JudgeText>
                Jurado especialista: <br /> {data.judge.name}
              </JudgeText>
            </Col>
            <JudgeDescription>"{data.judge.description}"</JudgeDescription>
          </JudgeContainer>
        )}
        {voters.length >= 2 && (
          <>
            <RankingList>
              {voters.map((voter, index) => {
                if (index <= 2) {
                  return (
                    <RankingColumn>
                      <RankingImage
                        style={{
                          backgroundImage: `url(${
                            voter?.imgPath
                              ? apiPath + voter.imgPath
                              : "../../../images/placeholder.png"
                          })`,
                          cursor: "pointer",
                        }}
                      >
                        <Backdrop className="hovered" />
                        {index === 0 && (
                          <Crown src="../../../images/coroa.png" />
                        )}
                      </RankingImage>
                      <h2 className="hovered">{voter.name}</h2>
                      <h2 className="hovered">{voter.voteNumber} votos</h2>
                    </RankingColumn>
                  );
                }
              })}
            </RankingList>
          </>
        )}
      </Row>
      {data.players.length > 0 ? (
        <>
          <Title>Participantes</Title>
          <Input
            placeholder="Busque por um participante!"
            onChange={(e) => setName(e.target.value)}
          />
          <Select defaultValue="" onChange={(e) => setFilter(e.target.value)}>
            <option hidden disabled value="">
              Selecione uma região para filtrar
            </option>
            <option value={"all"}>Todas</option>
            <RegionOptions />
          </Select>
          <PlayersList>{handlePlayers()}</PlayersList>
        </>
      ) : (
        <Text>Seja o primeiro competidor dessa competição!</Text>
      )}
    </Container>
  );
}
