import React from "react";
import Main from "../../layout/Main";
import { Container, Title, SectionTitle, Paragraph } from "./styles";

export default function Termos() {
  return (
    <Main>
      <Title>
        Termos e condicoes gerais de uso do site www.master5brasil.com.br
      </Title>
      <Paragraph>
        Os serviços do ​www.master5brasil.com.br​ são fornecidos pela
        pessoajurídica com a seguinte Razão Social Irmãos Nabak, inscrito no
        CNPJ/CPF sob o nº 37.958.374/0001-24, titular da propriedade intelectual
        sobre software, website, aplicativos, conteúdos e demaisativos
        relacionados à plataforma www.master5brasil.com.br
      </Paragraph>
      <SectionTitle>1. Do objeto</SectionTitle>
      <Paragraph>
        A plataforma visa licenciar o uso de seu software, website, aplicativos
        e demais ativos de propriedade intelectual, fornecendo ferramentas para
        auxiliar e dinamizar o dia a dia dos seus usuários.
      </Paragraph>
      <Paragraph>
        A plataforma caracteriza-se pela prestação do seguinte serviço:
        organização de competições online.
      </Paragraph>
      <SectionTitle>2. Da aceitação</SectionTitle>
      <Paragraph>
        O presente Termo estabelece obrigações contratadas de livre eespontânea
        vontade, por tempo indeterminado, entre a plataforma e as pessoas
        físicas ou jurídicas, usuárias do site.
      </Paragraph>
      <Paragraph>
        Ao utilizar a plataforma o usuário aceita integralmente as presentes
        normas e compromete-se a observá-las, sob o risco de aplicação das
        penalidades cabíveis.
      </Paragraph>
      <Paragraph>
        A aceitação do presente instrumento é imprescindível para o acesso e
        para a utilização de quaisquer serviços fornecidos pela empresa. Caso
        não concorde com as disposições deste instrumento, o usuário não deve
        utilizá-los.
      </Paragraph>
      <SectionTitle>3. Do acesso dos usuários</SectionTitle>
      <Paragraph>
        Serão utilizadas todas as soluções técnicas à disposição do responsável
        pela plataforma para permitir o acesso ao serviço 24 (vinte e quatro)
        horas por dia, 7 (sete) dias por semana. No entanto, a navegação na
        plataforma ou em alguma de suas páginas poderá ser interrompida,
        limitada ou suspensa para atualizações, modificações ou qualquer ação
        necessária ao seu bom funcionamento.
      </Paragraph>
      <SectionTitle>4. Do cadastro</SectionTitle>
      <Paragraph>
        O acesso às funcionalidades da plataforma exigirá a realização de um
        cadastro prévio e, a depender dos serviços ou produtos escolhidos, o
        pagamento de determinado valor.
      </Paragraph>
      <Paragraph>
        Ao se cadastrar o usuário deverá informar dados completos, recentes e
        válidos, sendo de sua exclusiva responsabilidade manter referidos dados
        atualizados, bem como o usuário se compromete com a veracidade dos dados
        fornecidos.
      </Paragraph>
      <Paragraph>
        Menores de 18 anos e aqueles que não possuírem plena capacidade civil
        deverão obter previamente o consentimento expresso de seus responsáveis
        legais para utilização da plataforma e dos serviços ou produtos, sendo
        de responsabilidade exclusiva dos mesmos o eventual acesso por menores
        de idade e por aqueles que não possuem plena capacidade civil sem a
        prévia autorização.
      </Paragraph>
      <Paragraph>
        Mediante a realização do cadastro o usuário declara e garante
        expressamente ser plenamente capaz, podendo exercer e usufruir
        livremente dos serviços e produtos.
      </Paragraph>
      <Paragraph>
        O usuário deverá fornecer um endereço de e-mail válido, através do qual
        o site realizará todas comunicações necessárias.
      </Paragraph>
      <Paragraph>
        Após a confirmação do cadastro, o usuário possuirá um login e uma senha
        pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta
        forma, compete ao usuário exclusivamente a manutenção de referida senha
        de maneira confidencial e segura, evitando o acesso indevido às
        informações pessoais.
      </Paragraph>
      <Paragraph>
        Toda e qualquer atividade realizada com o uso da senha será de
        responsabilidade do usuário, que deverá informar prontamente a
        plataforma em caso de uso indevido da respectiva senha.
      </Paragraph>
      <Paragraph>
        Não será permitido ceder, vender, alugar ou transferir, de qualquer
        forma, a conta, que é pessoal e intransferível.
      </Paragraph>
      <Paragraph>
        Caberá ao usuário assegurar que o seu equipamento seja compatível com as
        características técnicas que viabilize a utilização da plataforma e dos
        serviços ou produtos.
      </Paragraph>
      <Paragraph>
        O usuário poderá, a qualquer tempo, requerer o cancelamento de seu
        cadastro junto ao site. O seu descadastramento será realizado o mais
        rapidamente possível, desde que não sejam verificados débitos em aberto.
      </Paragraph>
      <Paragraph>
        O usuário, ao aceitar os Termos e Política de Privacidade, autoriza
        expressamente a plataforma a coletar, usar, armazenar, tratar, ceder ou
        utilizar as informações derivadas do uso dos serviços, do site e
        quaisquer plataformas, incluindo todas as informações preenchidas pelo
        usuário no momento em que realizar ou atualizar seu cadastro, além de
        outras expressamente descritas na Política de Privacidade que deverá ser
        autorizada pelo usuário.
      </Paragraph>
      <SectionTitle>5. Dos serviços</SectionTitle>
      <Paragraph>
        A plataforma poderá disponibilizar para o usuário um conjunto específico
        de funcionalidades e ferramentas para otimizar o uso dos serviços.
      </Paragraph>
      <Paragraph>
        Na plataforma os serviços oferecidos estão descritos e apresentados com
        o maior grau de exatidão, contendo informações sobre suas
        características, qualidades, quantidades, composição e preço.
      </Paragraph>
      <Paragraph>
        Antes de finalizar a compra sobre determinado produto ou serviço, o
        usuário deverá se informar sobre as suas especificações e sobre a sua
        destinação.
      </Paragraph>
      <SectionTitle>6. Dos preços</SectionTitle>
      <Paragraph>
        A plataforma se reserva no direito de reajustar unilateralmente, a
        qualquer tempo, os valores dos serviços ou produtos sem consulta ou
        anuência prévia do usuário.
      </Paragraph>
      <Paragraph>
        Os valores aplicadas são aqueles que estão em vigor no momento do
        pedido.
      </Paragraph>
      <Paragraph>
        Os preços são indicados em reais, as quais são especificadas à parte e
        são informadas ao usuário antes da finalização do pedido.
      </Paragraph>
      <Paragraph>
        Na contratação de determinado serviço, a plataforma poderá solicitar as
        informações financeiras do usuário, como CPF, endereço de cobrança e
        dados de cartões. Ao inserir referidos dados o usuário concorda que
        sejam cobrados, de acordo com a forma de pagamento que venha a ser
        escolhida, os preços então vigentes e informados quando da contratação.
        Referidos dados financeiros poderão ser armazenadas para facilitar
        acessos e contratações futuras.
      </Paragraph>
      <SectionTitle>7. Do suporte</SectionTitle>
      <Paragraph>
        Em caso de qualquer dúvida, sugestão ou problema com a utilização da
        plataforma, o usuário poderá entrar em contato com o suporte, através do
        email ​master5brasil@gmail.com​.
      </Paragraph>
      <Paragraph>
        Estes serviços de atendimento ao usuário estarão disponíveis nos
        seguintes dias e horários: Segunda a Sábado de 8 às 18 horas.
      </Paragraph>
      <SectionTitle>8. Das responsabilidades</SectionTitle>
      <Paragraph>É de responsabilidade do usuário:</Paragraph>
      <Paragraph>
        a) a correta utilização da plataforma, dos serviços ou produtos
        oferecidos, prezando pela boa convivência, pelo respeito e cordialidade
        entre os usuários;
      </Paragraph>
      <Paragraph>
        b) pelo cumprimento e respeito ao conjunto de regras disposto nesse
        Termo de Condições Geral de Uso, na respectiva Política de Privacidade e
        na legislação nacional e internacional;
      </Paragraph>
      <Paragraph>
        c) pela proteção aos dados de acesso à sua conta/perfil (login e senha).
      </Paragraph>
      <Paragraph>
        É de responsabilidade da plataforma Master 5 Brasil:
      </Paragraph>
      <Paragraph>
        a) indicar as características do serviço ou produto;
      </Paragraph>
      <Paragraph>
        b) as informações que foram por ele divulgadas, sendo que os comentários
        ou informações divulgadas por usuários são de inteira responsabilidade
        dos próprios usuários;
      </Paragraph>
      <Paragraph>
        A plataforma não se responsabiliza por links externos contidos em seu
        sistema que possam redirecionar o usuário à ambiente externo a sua rede.
      </Paragraph>
      <Paragraph>
        Não poderão ser incluídos links externos ou páginas que sirvam para fins
        comerciais ou publicitários ou quaisquer informações ou mídias ilícitas,
        violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou
        ofensivas.
      </Paragraph>
      <SectionTitle>9. Dos direitos autorais</SectionTitle>
      <Paragraph>
        O presente Termo de Uso concede aos usuários uma licença não exclusiva,
        não transferível e não sublicenciável, para acessar e fazer uso da
        plataforma e dos serviços e produtos por ela disponibilizados.
      </Paragraph>
      <Paragraph>
        A estrutura do site ou aplicativo, as marcas, logotipos, nomes
        comerciais, layouts, gráficos e design de interface, imagens,
        ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de
        som e áudio, programas de computador, banco de dados, arquivos de
        transmissão e quaisquer outras informações e direitos de propriedade
        intelectual da razão social Irmãos Nabak, observados os termos da ​Lei
        da Propriedade Industrial​ (Lei nº ​9.279​/96), ​Lei de Direitos
        Autorais (Lei nº ​9.610​/98) e ​Lei do Software​ (Lei nº ​9.609​/98),
        estão devidamente reservados.
      </Paragraph>
      <Paragraph>
        Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de
        modo que o acesso não gera qualquer direito de propriedade intelectual
        ao usuário, exceto pela licença limitada ora concedida.
      </Paragraph>
      <Paragraph>
        O uso da plataforma pelo usuário é pessoal, individual e intransferível,
        sendo vedado qualquer uso não autorizado, comercial ou não-comercial.
        Tais usos consistirão em violação dos direitos de propriedade
        intelectual da razão social Irmãos Nabak, puníveis nos termos da
        legislação aplicável.
      </Paragraph>
      <SectionTitle>10. Das sanções</SectionTitle>
      <Paragraph>
        Sem prejuízo das demais medidas legais cabíveis, a razão socialIrmãos
        Nabak poderá, a qualquer momento, advertir, suspender oucancelar a conta
        do usuário:
      </Paragraph>
      <Paragraph>
        a) que violar qualquer dispositivo do presente Termo;
      </Paragraph>
      <Paragraph>b) que descumprir os seus deveres de usuário;</Paragraph>
      <Paragraph>
        c) que tiver qualquer comportamento fraudulento, doloso ou que ofenda a
        terceiros.
      </Paragraph>
      <SectionTitle>11. Da recisão</SectionTitle>
      <Paragraph>
        A não observância das obrigações pactuadas neste Termo de Uso ou da
        legislação aplicável poderá, sem prévio aviso, ensejar a imediata
        rescisão unilateral por parte da razão social Irmãos Nabak e o bloqueio
        de todos os serviços prestados ao usuário.
      </Paragraph>
      <SectionTitle>11. Das alterações</SectionTitle>
      <Paragraph>
        Os itens descritos no presente instrumento poderão sofrer alterações,
        unilateralmente e a qualquer tempo, por parte de Irmãos Nabak, para
        adequar ou modificar os serviços, bem como para atender novas exigências
        legais. As alterações serão veiculadas pelo site
        www.master5brasil.com.br​ e o usuário poderá optar por aceitar o novo
        conteúdo ou por cancelar o uso dos serviços, caso seja assinante de
        algum serviço.
      </Paragraph>
      <SectionTitle>13. Da política de privacidade</SectionTitle>
      <Paragraph>
        Além do presente Termo, o usuário deverá consentir com as disposições
        contidas na respectiva Política de Privacidade a ser apresentada a todos
        os interessados dentro da interface da plataforma.
      </Paragraph>
      <SectionTitle>14. Do foro</SectionTitle>
      <Paragraph style={{ fontWeight: "bold" }}>
        Para a solução de controvérsias decorrentes do presente instrumento será
        aplicado integralmente o Direito brasileiro.
      </Paragraph>
      <Paragraph style={{ fontWeight: "bold" }}>
        Os eventuais litígios deverão ser apresentados no foro da comarca em que
        se encontra a sede da empresa.
      </Paragraph>
    </Main>
  );
}
