import styled, { keyframes } from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";

import { FaUserFriends } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { ImQuotesLeft } from "react-icons/im";

// Animation

const shake = keyframes`

0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  background-image: url("../../../images/01_bg.jpg");
  background-size: cover;
  background-position: bottom;
  z-index: 2;
  @media (max-width: 800px) {
    padding-bottom: 30px;
  }
`;
export const DarkContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  z-index: 2;
`;
export const TextHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  img {
    width: 75px;
    height: 75px;
    margin-top: 50px;
  }
`;
export const Title = styled.div`
  font-size: 3rem;
  color: var(--white);
  padding: 32px;
  font-family: var(--title-font);
  @media (max-width: 600px) {
    text-align: center;
  }
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  padding: 10px;
`;

export const Ratings = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  justify-self: center;
  padding: 40px;
  margin: 1.3em;
  @media (max-width: 800px) {
    width: calc(100% - 80px);
    margin: unset;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  &:hover {
    animation: ${shake} 1s ease-in-out 0s infinite;
  }
  @media (max-width: 800px) {
    width: calc(100% - 64px);
  }
`;
export const UsersIcon = styled(FaUserFriends)`
  width: 60px;
  height: 60px;
  color: var(--white);
  padding-right: 15px;
`;

export const MoneyIcon = styled(MdAttachMoney)`
  width: 60px;
  height: 60px;
  color: var(--white);
  padding-right: 15px;
`;

export const Button = styled(PrimaryButton)`
  margin: 32px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;
export const HorizontalLine = styled.div`
  width: min(80%, 350px);
  background: rgba(255, 255, 255, 0.4);
  height: 1px;
`;

export const QuoteCard = styled.div`
  position: absolute;
  bottom: -400px;
  right: 80px;
  width: min(30%, 520px);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  -webkit-box-shadow: 5px 5px 20px 5px var(--dark);
  box-shadow: 5px 5px 20px 5px var(--dark);

  @media (max-width: 800px) {
    align-self: center;
    width: calc(100% - 80px);
    position: unset;
    padding: 20px;
  }
`;

export const QuotesIcon = styled(ImQuotesLeft)`
  padding: 20px;
  fill: var(--secondary);
  width: 2em;
  height: 2em;
  z-index: 2;
`;

export const Quote = styled.h2`
  width: 60%;
  color: var(--white);
  font-size: 1.3em;
  line-height: 2;
  padding: 0 5px 0 30px;
  margin: unset;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  max-height: 70%;
  font-family: var(--text-font);
  font-style: bold;
  text-align: center;
  z-index: 2;
  align-self: center;
  @media (max-width: 800px) {
    margin: 0 5px 0 5px;
    width: unset;
    height: unset;
    font-size: var(--font-md);
    padding: unset;
  }
`;
export const QuoteWritter = styled.span`
  letter-spacing: 1.6px;
  color: var(--secondary);
  font-size: 1em;
  width: 50%;
  align-self: flex-end;
  text-align: right;
`;
export const HomeImage = styled.div`
  position: absolute;
  background-position: inherit;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 85vh;
  z-index: 1;
`;

export const Span = styled.span`
  font-family: var(--title-font);
  text-transform: uppercase;
  color: #008aab;
`;

export const Span2 = styled.span`
  font-family: var(--title-font);
  text-transform: uppercase;
  color: #cf368d;
`;
export const SquaredFooter = styled.div`
  width: 75px;
  height: 75px;
  margin: 0 10px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/icon_01.png");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
