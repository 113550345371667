import React from "react";
import AboutUs from "../../components/AboutUs";
import Main from "../../layout/Main";

export default function Sobre() {
  return (
    <Main>
      <AboutUs />
    </Main>
  );
}
