import styled from "styled-components";
import Divider from "../../ui/Divider";
import PrimaryInput from "../../ui/PrimaryInput";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimarySelect from "../../ui/PrimarySelect";
import PrimaryTextArea from "../../ui/PrimaryTextArea";
import PrimaryFileInput from "../../ui/PrimaryFileInput";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70%;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--dark);
  text-align: center;
  margin-bottom: 1.5em;
`;
export const Divide = styled(Divider)`
  & .divide {
    border-color: var(--dark);
  }
`;
export const Input = styled(PrimaryInput)`
  color: var(--dark);
  &&::placeholder {
    color: var(--dark);
  }
  &[type="checkbox"] {
    width: unset;
    margin-left: 15px;
  }
`;
export const Select = styled(PrimarySelect)`
  width: 100%;
  color: var(--dark);
  &&::placeholder {
    color: var(--dark);
  }
`;

export const TextArea = styled(PrimaryTextArea)`
  width: 100%;
  color: var(--dark);
  &&::placeholder {
    color: var(--dark);
  }
`;

export const FileInput = styled(PrimaryFileInput)`
  width: calc(100% - 40px);
  margin-bottom: 1.7em;
`;
export const Label = styled.div`
  font-size: var(--font-sm);
  color: var(--dark);
  margin-bottom: 0.5em;
  span {
    font-size: 13px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
export const SubmitButton = styled(PrimaryButton)``;
