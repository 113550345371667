import React from "react";
import { Container, PageTitle, User, Name, Avatar, Logout } from "./styles";
import { useHistory } from "react-router-dom";
import { ImExit } from "react-icons/im";
import { logout } from "../../../services/auth";

export default function Topbar() {
  const history = useHistory();
  let title = history.location.pathname.split("/");
  title = title[1].replace("-", " de ");
  return (
    <Container>
      <PageTitle>{title}</PageTitle>
      <User>
        <Name>{localStorage.getItem("@Master5/name")}</Name>
        <Avatar />
        <Logout
          onClick={() => {
            logout();
            history.push("/");
          }}
        >
          Sair {"   "} <ImExit />
        </Logout>
      </User>
    </Container>
  );
}
