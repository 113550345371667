import React from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { Container, Text, HorizontalLine, ProfileImage } from "./styles";
import { mutate as mutateGlobal } from "swr";

export default function Header({ userData }) {
  const imageUpload = async (img) => {
    const formData = new FormData();
    formData.append(
      "image",
      new File([img], Date.now() + ".jpg", {
        type: "image/jpg",
      })
    );
    const { data } = await api.post(
      "/users/update/" + localStorage.getItem("@Master5/id"),
      formData
    );
    const updated = { ...userData, imgPath: data.imgPath };

    if (data.success) {
      mutateGlobal("/users/get/" + localStorage.getItem("@Master5/id"), {
        imgPath: `https://master5bucket.s3.sa-east-1.amazonaws.com/'${data.imgPath}`,
      });
      toast.success("Imagem Cadastrada com Sucesso!");
    } else {
      toast.error("Houve um erro ao adicionar/alterar a imagem");
    }
    try {
    } catch (error) {
      toast.error("Houve um erro ao adicionar/alterar a imagem");
    }
  };

  return (
    <Container>
      <ProfileImage
        src={`https://master5bucket.s3.sa-east-1.amazonaws.com/${userData?.imgPath}`}
        editable
        uploadFunction={imageUpload}
      />
      <Text>{userData?.name}</Text>
      <Text>{userData?.region}</Text>
      {/* <HorizontalLine /> */}
    </Container>
  );
}
