import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--primary-600);
`;

export const Title = styled.div`
  font-size: 2em;
  font-family: var(--title-font);
  color: var(--white);
  text-align: center;
  padding: 10px 10px;
  margin-top: 120px;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;
export const SectionTitle = styled.div`
  font-size: var(--font-md);
  font-family: var(--button-font);
  color: var(--secondary);
  padding: 0 20px 5px 20px;
`;

export const Paragraph = styled.div`
  font-size: var(--font-sm);
  color: var(--white);
  padding: 0 20px 10px 20px;
  text-align: justify;
`;
