import { MdClose } from "react-icons/md";
import styled from "styled-components";
import PrimaryButton from "../../components/ui/PrimaryButton";
export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

export const Card = styled.div`
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  position: relative;
  width: max(400px, 60%);
  height: 80vh;
  border-radius: var(--radius-sm);
  display: flex;
  padding: 0 32px;
  -webkit-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.75);
`;
export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 32px;
  width: 50%;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const Logo = styled.img`
  width: 95%;
  height: auto;
  margin-bottom: 25px;
`;

export const Text = styled.div`
  color: var(--white);
  font-size: var(--font-lg);
  text-align: center;
`;

export const Image = styled.img`
  width: 200px;
  height: auto;
  margin-top: 30px;
`;
export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: var(--secondary);
  font-size: var(--font-sm);
  margin: 10px 0;
  cursor: pointer;
  &:hover {
    color: var(--secondary-100);
  }
`;

export const CustomButton = styled(PrimaryButton)`
  align-self: center;
`;
export const TextMuted = styled.div`
  margin: 20px;
  color: var(--white);
  font-size: var(--font-sm);
  text-align: center;
`;
export const CloseIcon = styled(MdClose)`
  width: 32px;
  height: 32px;
  position: absolute;
  fill: var(--white);
  top: 10px;
  right: 20px;
  cursor: pointer;
`;
