import React from "react";
import Loading from "../../components/ui/Loading";
import useApi from "../../hooks/useApi";
import Main from "../../layout/Main";
import moment from "moment";
import {
  Container,
  Card,
  Header,
  ProfileImage,
  Text,
  HorizontalLine,
  User,
} from "./styles";

export default function ViewProfile({ match }) {
  const { data } = useApi(`users/get/${match.params.id}`);
  if (!data) {
    return <Loading />;
  }
  return (
    <Main>
      <Container>
        <Card>
          <Header>
            <ProfileImage src={data?.imgPath} />
            <Text>{data.name}</Text>
            <Text>{data?.email}</Text>
            <Text>{moment(data?.birth).utc().format("DD/MM/YYYY")}</Text>
            <Text>{data?.region}</Text>
            {/* <HorizontalLine /> */}
          </Header>
          {/* <User></User> */}
        </Card>
      </Container>
    </Main>
  );
}
