import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: unset;
  position: relative;
  background-image: url("../../../images/01_bg.jpg");
  background-size: cover;
`;

export const LeftHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(100%, 500px);
  min-height: 450px;
  margin-left: 4em;
`;
export const RightHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(100%, 500px);
  min-height: 450px;
  margin-right: 4em;
`;
export const Title = styled.h1`
  width: 70%;
  text-align: center;
  font-size: 40px;
  color: var(--white);
  margin-bottom: 45px;
  font-family: var(--title-font);
  text-transform: uppercase;
  @media (max-width: 800px) {
    font-size: 30px;
  }
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  margin-bottom: 40px;
`;
export const StartButton = styled(PrimaryButton)`
  outline: none;
  border: unset;
  color: var(--white);
  padding: 20px 1px;
  width: 100%;
  max-width: 290px;
  border-radius: 15px;
  text-transform: uppercase;
  background: var(--secondary);
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 3em;
  font-family: var(--button-font);
  font-size: 19px;
  // @media (max-width: 800px) {
  //   bottom: 0px;
  // }
  // @media (max-width: 600px) {
  //   display: none;
  // }
`;
export const HomeImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 100vh;
  z-index: 1;
`;

export const Image = styled.img`
  position: relative;
  background-image: url("/images/01_bg.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

// export const DownArrow = styled(IoIosArrowDown)`
//   width: 60px;
//   height: 60px;
//   position: absolute;
//   bottom: 50px;
//   fill: var(--secondary);
//   transition: all ease-in-out 0.2s;
//   &:hover {
//     transform: translateY(15px);
//   }
//   cursor: pointer;
// `;

export const DivHome = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;
