import React, { useState } from "react";
import { toast } from "react-toastify";
import { mutate as mutateGlobal } from "swr";
import api from "../../../services/api";
import moment from "moment";
import RegionOptions from "../../ui/RegionOptions";
import { Container, Title, Input, Select, Label, Form, Button } from "./styles";

export default function UserEdit({ userData }) {
  const [formData, setFormData] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e;
    if (name === "birth") {
      let birth = new Date(value).getTime();
      setFormData({ ...formData, [name]: birth });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleEmail = async () => {
    const { email } = formData;
    if (email !== undefined) {
      if (email.split("@").length > 1) {
        try {
          const { data } = await api.get("/users/getByEmail/" + formData.email);
          if (data === "") {
            setValidEmail(true);
          } else {
            toast.error("E-mail já cadastrado!");
          }
        } catch (error) {
          toast.error(
            "Oops, houve um erro no nosso servidor, tente novamente!"
          );
        }
      } else {
        toast.error("Insira um email válido!");
      }
    }
  };
  const validate = () => {
    let success = true;
    Object.keys(userData).map((key) => {
      if (formData?.[key] === "") {
        success = false;
      }
    });
    return success;
  };

  const handleSubmit = async () => {
    const validated = validate();
    if (validated && validEmail) {
      const success = await api.post(
        `/users/update/${localStorage.getItem("@Master5/id")}`,
        formData
      );
      if (success) {
        mutateGlobal("/users/get/" + localStorage.getItem("@Master5/id"), {
          _id: localStorage.getItem("@Master5/id"),
          ...formData,
        });
        toast.success("Dados atualizados com sucesso!");
      }
    } else {
      toast.error("Por favor, preencha corretamente seus dados!");
    }
  };

  return (
    <Container>
      <Title>Editar Perfil</Title>
      <Form>
        <Input
          name="name"
          onChange={(e) => handleChange(e.target)}
          defaultValue={userData?.name}
          placeholder="Qual seu nome?"
        />
        <Input
          name="email"
          onChange={(e) => handleChange(e.target)}
          onBlur={() => {
            handleEmail();
          }}
          defaultValue={userData?.email}
          placeholder="Qual seu email?"
        />
        {/* <Label>Qual sua data de nascimento?</Label> */}
        <Input
          name="birth"
          onChange={(e) => handleChange(e.target)}
          type="date"
          placeholder="Qual sua data de nascimento?"
          defaultValue={
            userData?.birth && moment.utc(userData.birth).format("YYYY-MM-DD")
          }
        />
        <Select
          name="region"
          onChange={(e) => handleChange(e.target)}
          defaultValue={userData?.region ? userData.region : ""}
        >
          <option value="" hidden disabled>
            Qual seu Estado?
          </option>
          <RegionOptions />
        </Select>
        <Button onClick={handleSubmit}>Editar Dados!</Button>
      </Form>
    </Container>
  );
}
