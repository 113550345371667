import React, { useState } from "react";
import Header from "../../components/Profile/Header";
import UserEdit from "../../components/Profile/UserEdit";
import Competitions from "../../components/Profile/Competitions";
import Loading from "../../components/ui/Loading";
import Tabs from "../../components/ui/Tabs";
import useApi from "../../hooks/useApi";
import Main from "../../layout/Main";
import { Container, Card, HomeImage } from "./styles";

export default function Profile() {
  const [component, setComponent] = useState(1);
  const { data } = useApi("/users/get/" + localStorage.getItem("@Master5/id"));
  const compData = useApi(
    "/users/history/" + localStorage.getItem("@Master5/id")
  );
  if (!data) {
    return <Loading />;
  }
  return (
    <Main>
      <Container>
        <Card>
          <Header userData={data} />
          <Tabs
            tabs={[
              {
                name: "Editar perfil",
                backgroundColor: "#fff",
                color: "#ce358d",
              },
              {
                name: "Competições não Iniciadas",
                backgroundColor: "#ce358d",
                color: "#6cc3de",
              },
              {
                name: "Competições em Andamento",
                backgroundColor: "#6cc3de",
                color: "#ce358d",
              },
              {
                name: "Competições Finalizadas",
                backgroundColor: "#00599c",
                color: "#fff",
              },
            ]}
            setComponent={setComponent}
          />
          {component === 1 && <UserEdit userData={data} />}
          {component === 2 && <Competitions compData={compData.data} />}
          {component === 3 && <Competitions started compData={compData.data} />}
          {component === 4 && <Competitions ended compData={compData.data} />}
        </Card>
        <HomeImage />
      </Container>
    </Main>
  );
}
