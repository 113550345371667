import styled from "styled-components";
import RoundedImage from "../../components/ui/RoundedImage";

export const Container = styled.div`
  padding: 120px 15px 20px 15px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Card = styled.div`
  background: transparent;
  border-radius: var(--radius-sm);
  width: min(80vw, 100%);
  margin-top: 20px;
  padding: 20px 15px;
  @media (max-width: 800px) {
    max-width: 350px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    max-width: 350px;
  }
`;

export const Text = styled.div`
  color: var(--white);
  font-weight: 600;
  font-size: var(--font-md);
  margin-top: 5px;
`;
export const HorizontalLine = styled.div`
  margin-top: 15px;
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const ProfileImage = styled(RoundedImage)``;
