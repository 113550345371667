import { useDispatch } from "react-redux";
let Vimeo = require("vimeo").Vimeo;
let client = new Vimeo(
  "3cd0ad3dc3f113395604d3e9f47c985d867518a8",
  "7V6f285Cb1sBJnA7OWj5U/4Ws01iKCyns4RSOOvZUP2gWPpVfkx5A4/vsQ5mrISDbgDDCvHkNoi3AbwD6mXdR0jraMJviHc+0CLOK8v/P1lZkUNpkdFBv1mUQUgZjjlY",
  "4ed034cc8910aae56282f64636977f61"
);
export const Transcode = (path) => {
  const dispatch = useDispatch();
  return () => {
     client.request(path, function (error, body, status_code, headers) {
      dispatch({ type: "ADD_TRANSCODE", transcode: body.transcode.status });
    });
  };
};

