import styled from "styled-components";
import PrimarySelect from "../PrimarySelect";

export const Container = styled.div`
  width: 85%;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 7px;
  z-index: 2;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const VerticalLine = styled.div`
  height: 32px;
  margin: 0 10px;
  border: 1px solid var(--secondary);
  @media (max-width: 600px) {
    display: none;
  }
`;

export const TabName = styled.div`
  padding: 15px;
  border-radius: 45px;
  flex: 1;
  text-align: center;
  font-family: var(--title-font);
  font-size: var(--font-sm);
  color: #fff;
  font-weight: bold;
  background: transparent;
  border-radius: var(--radius-sm);
  cursor: pointer;
  text-transform: uppercase;
  z-index: 2;

  // // &&:hover {
  // //   background: var(--secondary);
  // //   color: var(--white);
  // // }
  &.active {
    max-width: 210px;
    max-height: 20px;
    background: var(--secondary);
    color: var(--white);
    border-radius: 30px;
    max-weight: 100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 25px;
    @media (max-width: 800px) {
      max-width: 350px;
    }
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
    min-width: 200px;
  }
`;

export const Select = styled(PrimarySelect)`
  option {
    border-radius: 25px;
    color: var(--white);
    text-align: center;
    background-color: var(--primary-600);
    font-size: 12px;
    z-index: 2;
    @media (max-width: 800px) {
      max-width: 350px;
  }
  option:checked {
    background: unset;
    background: var(--secondary);
    color: var(--white);
    @media (max-width: 800px) {
      max-width: 350px;
  }
  @media (max-width: 800px) {
    max-width: 350px;
  }
`;
