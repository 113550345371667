import React from "react";
import {
  Container,
  Subtitle,
  Title,
  Row,
  Col,
  Card,
  SquaredNumber1,
  SquaredNumber2,
  SquaredNumber3,
  SquaredNumber4,
  SquaredNumber5,
  SquaredTitle,
  SquaredDiv,
  Span,
  Span2,
  Number,
  CardTitle,
  CardDescription,
  HorizontalLine,
  VerticalLine,
  HomeImage,
} from "./styles";

export default function InstructionsSection() {
  return (
    <Container>
      <SquaredDiv>
        <SquaredTitle />

        <Title>COMO JOGAR?</Title>
      </SquaredDiv>
      <HomeImage />
      <Row>
        <Col className="customCol">
          <Card className="first">
            <Col className="numberCol">
              <SquaredNumber1 />
            </Col>
            <Col>
              <CardTitle>
                Inscreva-se na categoria que mais combina com você;
              </CardTitle>
            </Col>
          </Card>
          <Card className="second">
            <Col className="numberCol">
              <SquaredNumber2></SquaredNumber2>
            </Col>
            <Col>
              <CardTitle>
                Faça uma publicação de acordo com o tema da competição;
              </CardTitle>
              <CardDescription>(Abuse da criatividade)</CardDescription>
            </Col>
          </Card>
          <Card className="third">
            <Col className="numberCol">
              <SquaredNumber3></SquaredNumber3>
            </Col>
            <Col>
              <CardTitle>
                Convoque a familia e os amigos para votar.
                <Span>(Quanto mais votos mais chances tem de ganhar)</Span>
              </CardTitle>
            </Col>
          </Card>
        </Col>
        <Col className="customCol">
          <Card className="fourth">
            <Col className="numberCol">
              <SquaredNumber4></SquaredNumber4>
            </Col>
            <Col>
              <CardTitle className="customTxt1">
                Os 5 mais votados, vão para a próxima fase, tornam-se Master 5 e
                serão julgados pelo público +1 Jurado especialista na categoria;
              </CardTitle>
            </Col>
          </Card>
          <Card className="last">
            <Col className="numberCol">
              <SquaredNumber5></SquaredNumber5>
            </Col>
            <Col>
              <CardTitle>
                O mais bem pontuado será o <Span2>grande vencedor</Span2> e leva
                a premiação!
              </CardTitle>
            </Col>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
