import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";

export const Container = styled.div`
  width: 80%;
  background: transparent;
  border-radius: var(--radius-sm);
  margin-top: 30px;
  padding: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: var(--font-lg);
  font-family: var(--competition-font);
  color: var(--white);
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  margin-bottom: 40px;
  text-align: center;
`;

export const HorizontalLine = styled.div`
  margin: 15px 0;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.6);
`;

export const VoteContainer = styled.div`
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  span {
    color: var(--white);
    font-size: var(--font-md);
  }
`;

export const Button = styled(PrimaryButton)`
  margin: unset;
`;

export const Image = styled.img`
  width: 85%;
`;

export const DocumentHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const PageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  span {
    color: var(--white);
    font-size: var(--font-sm);
  }
`;

export const PreviousIcon = styled(IoIosArrowDropleft)`
  fill: var(--secondary);

  height: 40px;
  width: 50px;
  cursor: pointer;
`;

export const NextIcon = styled(IoIosArrowDropright)`
  fill: var(--secondary);

  height: 40px;
  width: 50px;
  cursor: pointer;
`;
