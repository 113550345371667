import React, { useEffect, useState } from "react";
import Admin from "../../../layout/Admin";

import { columns, tableIcons } from "./tableConfig";
import { Container } from "./styles";
import MaterialTable from "material-table";
import Loading from "../../../components/ui/Loading";
import useApi from "../../../hooks/useApi";

export default function TabelaJurados() {
  const { data } = useApi("/judge/getAll");

  if (!data) {
    return <Loading />;
  }
  return (
    <Admin>
      <Container>
        <MaterialTable
          title=""
          icons={tableIcons}
          localization={{
            body: {
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
            pagination: {
              labelRowsSelect: "linhas",
              labelDisplayedRows: "{count} de {from}-{to}",
              firstTooltip: "Primeira página",
              previousTooltip: "Página anterior",
              nextTooltip: "Próxima página",
              lastTooltip: "Última página",
            },
            toolbar: {
              exportTitle: "Exportar",
              exportAriaLabel: "Exportar",
              searchTooltip: "Pesquisar",
              searchPlaceholder: "Pesquisar",
            },
          }}
          options={{
            search: true,
            paging: true,
            filtering: false,
            exportButton: true,
            exportDelimiter: ";",
            actionsColumnIndex: -1,
          }}
          style={{
            width: "100%",
          }}
          columns={columns}
          data={data.map((item) => ({
            name: item.name,
            competition: item.competition[0]?.name,
          }))}
        />
      </Container>
    </Admin>
  );
}
