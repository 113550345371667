import React from "react";
import Competitions from "../../../components/Competicoes/Competitions";

import Main from "../../../layout/Main";
import { Container } from "./styles";
export default function UserComps() {
  return (
    <Main>
      <Container>
        <Competitions />
      </Container>
    </Main>
  );
}
