import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { CustomButton } from "../../../pages/Login/styles";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

export const ModalBody = styled.div`
  width: min(100%, 450px);
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--radius-sm);
`;

export const ModalTitle = styled.div`
  font-size: var(--font-lg);
  text-align: center;
  margin: 40px auto 15px auto;
`;
export const ModalText = styled.div`
  text-align: justify;
  margin: 0px 30px;
`;
export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const ReturnButton = styled(CustomButton)`
  margin-left: 20px;
  max-width: 150px;
`;
export const ConfirmButton = styled(CustomButton)`
  margin-right: 20px;
  max-width: 150px;
  &:hover {
    background: var(--red);
  }
`;

export const CloseIcon = styled(MdClose)`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
`;
