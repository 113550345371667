import React from "react";
import { Container, Image, Logo } from "./styles";

export default function Loading() {
  return (
    <Container>
      <Image />
      <Logo src="../../../images/logotipo_carregamento.png" />
    </Container>
  );
}
