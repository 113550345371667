import React from "react";
import { Container, Title, Holder, Text } from "./styles";
import { useHistory } from "react-router-dom";
import FlipCard from "../../ui/FlipCard";

export default function Competitions({ ended, compData, started }) {
  const history = useHistory();
  let counter;
  return (
    <Container>
      <Title>
        {ended ? "Competições Finalizadas" : "Competições em Andamento"}
      </Title>
      <Holder>
        {compData.length !== 0 ? (
          compData.map((competition, index) => {
            if (ended) {
              counter = 0;
              if (competition?.actualPhase === 4) {
                counter++;
                return (
                  <FlipCard
                    name={competition.name}
                    numPlayers={competition.numPlayers}
                    id={competition._id}
                    onClick={() =>
                      history.push(
                        `/competicao/${competition._id}-${localStorage.getItem(
                          "@Master5/id"
                        )}`
                      )
                    }
                  />
                );
              }
              if (counter === 0) {
                return (
                  <Text>Você não está em nenhuma competição concluída!</Text>
                );
              }
            } else if (started) {
              counter = 0;

              if (competition?.actualPhase < 4) {
                counter++;
                return (
                  <FlipCard
                    name={competition.name}
                    numPlayers={competition.numPlayers}
                    id={competition._id}
                    onClick={() =>
                      history.push(
                        `/competicao/${competition._id}-${localStorage.getItem(
                          "@Master5/id"
                        )}`
                      )
                    }
                  />
                );
              }
              if (counter === 0) {
                return (
                  <Text>Você não está em nenhuma competição em andamento!</Text>
                );
              }
            } else {
              counter = 0;

              if (competition.actualPhase === undefined) {
                counter++;

                return (
                  <FlipCard
                    name={competition.name}
                    numPlayers={competition.numPlayers}
                    id={competition._id}
                    path={`/competicao/${
                      competition._id
                    }-${localStorage.getItem("@Master5/id")}`}
                  />
                );
              }
              if (counter === 0) {
                return (
                  <Text>
                    Você não está em nenhuma competição a ser iniciada!
                  </Text>
                );
              }
            }
          })
        ) : (
          <Title>Você ainda não participou de nenhuma competição...</Title>
        )}
      </Holder>
    </Container>
  );
}
