import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: radial-gradient(circle, var(--dark) 40%, var(--primary) 100%); */
  // background: var(--primary-700);
  /* width: 100vw; */
  /* min-height: 100vh; */
  padding: 130px 20px;
  background-image: url("../../../images/bg_ojogo.jpg");
  background-size: cover;

  position: relative;
  @media (max-width: 800px) {
    padding: 50px 20px;
  }
`;

export const HomeVideo = styled.video`
  /* margin: 120px 0 20px 0; */
  width: 45%;
  height: auto;
  z-index: 2;
  max-height: calc(100vh - 120px);
  /* position: absolute; */
`;

export const Title = styled.div`
  font-size: 3em;
  font-family: var(--title-font);
  color: var(--white);
  margin-bottom: 1em;
`;

export const AltTitle = styled.div`
  font-size: 2em;
  font-family: var(--title-font);
  color: #fff
  margin-bottom: 1em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px
`;

export const TextContainer = styled.div`
  width: 40%;
  font-size: var(--font-md);
  padding: 15px;
  min-height: 250px;
  color: var(--white);
  text-align: justify;
  display: flex;
  align-items: center;
  flex-direction: column;
  span {
    color: var(--white);
    font-size: var(--font-md);
  }
  @media (max-width: 800px) {
    width: 80%;
  }
  /* background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../images/texture.png");
  background-position: center;
  -webkit-box-shadow: 5px 5px 20px 5px var(--dark);
  box-shadow: 5px 5px 20px 5px var(--dark); */
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 60px;
  width: 100%;
  .video {
    z-index: 2;
    width: 45%;
    height: auto;
    margin-top: 60px;
    max-height: calc(100vh - 120px);
  }
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .video {
      margin-top: 60px;
      width: 100%;
      height: auto;
    }
  }
`;
export const SpanLi = styled.div`
  font-weight: bold;
  line-height: 25px;
  font-family: var(--text-font);
  font-size: 15px;
  z-index: 2;
  span {
    font-size: 20px;
    font-family: var(--title-font);
    color: #cf368d;
    text-transform: uppercase;
  }
`;
export const HomeImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 78vh;
  z-index: 1;
`;
