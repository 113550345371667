import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Welcome,
  Logo,
  LoginForm,
  Link,
  CustomButton,
  TextMuted,
  CloseIcon,
} from "./styles";

import { useHistory } from "react-router-dom";

import api from "../../services/api";
import { logout } from "../../services/auth";
import { toast } from "react-toastify";
import SignUpModal from "../../components/Login/SignUpModal";
import PasswordModal from "../../components/Login/PasswordModal";
import Divider from "../../components/ui/Divider";
import PrimaryInput from "../../components/ui/PrimaryInput";

export default function Login({ setOpen }) {
  const [newData, setNewData] = useState(null);
  const [modal, setModal] = useState(false);
  const [signUp, setSignUp] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (newData?.email && newData?.password) {
      try {
        const { data } = await api.post("logger/login", { ...newData });
        if (data.success) {
          localStorage.setItem("@Master5/token", data.token);
          localStorage.setItem("@Master5/id", data.user._id);
          localStorage.setItem("@Master5/access", data.user.access);
          localStorage.setItem("@Master5/email", data.user.email);
          localStorage.setItem("@Master5/name", `${data.user.name}`);
          localStorage.setItem("@Master5/region", `${data.user?.region}`);
          if (localStorage.getItem("@Master5/access") === "999") {
            history.push("/dashboard");
          } else {
            window.location.reload();
          }
        }
      } catch (error) {
        toast.error("Login ou senha inválidos!");
      }
    } else {
      toast.error("Por favor, insira todos os dados!");
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Container>
      <Card>
        <CloseIcon onClick={() => setOpen(false)} />
        <Welcome>
          <Logo src="/images/logotipo_inicio.png" alt="Logo" />
        </Welcome>
        <LoginForm>
          <form>
            <PrimaryInput
              name="email"
              onChange={(e) => handleChange(e)}
              placeholder="E-mail"
            />
            <PrimaryInput
              name="password"
              onChange={(e) => handleChange(e)}
              placeholder="Senha"
              type="password"
            />
          </form>
          <Link onClick={() => setModal(true)}>Esqueceu sua senha?</Link>
          <CustomButton onClick={handleLogin}>ENTRAR</CustomButton>
          <Divider />
          <TextMuted>
            Não possui conta? {"    "}
            <Link onClick={() => setSignUp(true)}> Cadastre-se aqui!</Link>
          </TextMuted>
        </LoginForm>
      </Card>

      {modal && <PasswordModal setOpen={setModal} />}
      {signUp && <SignUpModal setOpen={setSignUp} />}
    </Container>
  );
}
