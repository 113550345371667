import { createStore } from "redux";

const INITIAL_STATE = {
  transcode: [false],
};

function vimeo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TRANSCODE":
      return { ...state, data: [...state.data, action.status] };
    default:
      return state;
  }
}

const store = createStore(vimeo);

export default store;
