import React from "react";
import { Container, Line } from "./styles";

export default function Divider() {
  return (
    <Container>
      <Line className="divide" />
      <Line className="divide" />
    </Container>
  );
}
