import React from "react";
import { Container, Background, Vetor } from "./styles";
import Navbar from "../../components/Main/Navbar";
import Footer from "../../components/ui/Footer";

export default function Main({ children }) {
  return (
    <Container>
      <Navbar />
      {children}
      <Footer />
    </Container>
  );
}
