import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AdminRoute, UserRoute } from "./types";
import routing from "./routing.js";
import ScrollTop from "./ScrollTop";

const Routes = () => {
  return (
    <Router>
      <ScrollTop />
      <Switch>
        {routing.public.map((route, index) => {
          if (route?.user) {
            return (
              <UserRoute
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            );
          } else {
            return (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            );
          }
        })}
        {/* {routing.user.map((route, index) => {
          if (route?.dropdown) {
            return route.dropdown.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                exact
                component={item.component}
              />
            ));
          } else {
            return (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            );
          }
        })} */}
        {routing.admin.map((route, index) => {
          if (route?.dropdown !== undefined) {
            return route.dropdown.map((item, index) => (
              <AdminRoute
                key={index}
                path={item.path}
                exact
                component={item.component}
              />
            ));
          } else {
            return (
              <AdminRoute
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            );
          }
        })}
      </Switch>
    </Router>
  );
};

export default Routes;
