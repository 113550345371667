import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaCog, FaPlusCircle } from "react-icons/fa";
import { apiPath } from "../../../services/api";

const Image = styled.div`
  background-size: 100px auto;
  background-repeat: no-repeat;
  background-color: var(--light-gray);
  height: 150px;
  width: 150px;
  background-image: "../../../images/icon_profile.png";
  background-position: center;
  border-radius: var(--radius-circle);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 15px;
  .hovered {
    display: none;
    border: 15px;
  }
  &:hover {
    .hovered {
      display: block;
      border: 15px;
    }
  }
`;

const Backdrop = styled.div`
  border-radius: var(--radius-circle);
  height: 200px;
  width: 200px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: var(--font-md);
  font-weight: bold;
  color: var(--secondary);
  z-index: 2;
`;

const Add = styled(FaPlusCircle)`
  color: var(--secondary);
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 50px;
  z-index: 2;
`;

const Edit = styled(FaCog)`
  color: var(--secondary);
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 50px;
  z-index: 2;
`;

export default function RoundedImage({
  src = null,
  editable = false,
  uploadFunction = null,
}) {
  const [image, setImage] = useState(null);
  // const [path, setPath] = useState(null);

  const handleChange = (image) => {
    setImage(image);
    // setPath(URL.createObjectURL(image));
  };

  useEffect(() => {
    if (image && uploadFunction) {
      uploadFunction(image);
    }
  }, [image]);

  if (editable) {
    return (
      <Image
        style={{
          border: "4px solid #fff",
          backgroundColor: "#000",
          zIndex: "1",
          backgroundImage: `url(${src ? src : "../../../images/icon_profile.png"})`,
          backgroundSize: "cover",
          cursor: "pointer",
          zIndex: "2",
        }}
        onClick={() => {
          document.getElementById("fileInput").click();
        }}
      >
        <Backdrop className="hovered" />
        <Text className="hovered">
          {src ? "Editar imagem" : "Adicionar imagem"}
        </Text>
        {src ? <Edit className="hovered" /> : <Add className="hovered" />}
        <input
          type="file"
          id="fileInput"
          accept={".jpg, .png, .jpeg"}
          style={{ display: "none", width: "100%", height: "100%" }}
          onChange={(e) => handleChange(e.target.files[0])}
        />
      </Image>
    );
  } else {
    return (
      <Image
        style={{
          backgroundImage: `url(${
            src ? src : "../../../images/placeholder.png"
          })`,
        }}
      >
        <Backdrop className="hovered" />
      </Image>
    );
  }
}
