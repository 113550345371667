import styled from "styled-components";

export const Container = styled.div`
  padding: 120px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary-700);
  @media (max-width: 600px) {
    padding: 30px 10px;
  }
`;
