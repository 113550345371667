import styled from "styled-components";

const PrimaryTextArea = styled.textarea`
  border: 1px solid var(--secondary);
  border-radius: var(--radius-sm);
  min-height: 100px;
  background: transparent;
  flex: 1;
  margin-bottom: 2em;
  outline: none;
  padding: 10px;
  color: var(--white);
  font-size: var(--font-sm);
  &&:focus {
    border: 1px solid var(--secondary-100);
  }

  &&:hover {
    border: 1px solid var(--secondary-100);
  }

  &&::placeholder {
    color: var(--white);
  }
`;
export default PrimaryTextArea;
