import styled from "styled-components";
import PrimaryButton from "../../../components/ui/PrimaryButton";

export const Title = styled.div`
  font-size: var(--font-lg);
  margin-bottom: 10px;
  text-align: center;
`;

export const Subtitle = styled.div`
  font-size: var(--font-md);
  margin-bottom: 20px;
  text-align: center;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  max-width: unset;
  text-transform: capitalize;
`;
