import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSpring } from "react-spring";
import { apiPath } from "../../../services/api";

import {
  Container,
  Card,
  CardBody,
  Title,
  Description,
  Subtitle,
  Text,
} from "./styles";

export default function CompetitionCard({ player, type, competition_id }) {
  const [flipped, set] = useState(false);
  const history = useHistory();
  const [backgroundUrl, setBackgroundUrl] = useState(null);

  useEffect(() => {
    switch (type) {
      case "image":
        setBackgroundUrl(apiPath + player.content.path);
        break;

      case "video":
        let path = player.content.path.replace("/videos/", "/video/");
        Axios.get(`http://vimeo.com/api/v2${path}.json`)
          .then(({ data }) => {
            setBackgroundUrl(data[0].thumbnail_large);
          })
          .catch((err) => {
            console.log(err);
          });
        setBackgroundUrl(
          `http://vimeo.com/api/v2${path}.json?callback=showThumb`
        );
        break;

      default:
        break;
    }
    if (player.content.description.length > 50) {
      player.content.description =
        player.content.description.substring(0, 49) + "...";
    }
  }, []);

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  return (
    <Container>
      <Card
        onMouseEnter={() => set((state) => !state)}
        onMouseLeave={() => set((state) => !state)}
        onClick={() =>
          history.push(`/competicao/${competition_id}-${player.user_id}`)
        }
      >
        <CardBody
          style={{
            opacity: opacity.interpolate((o) => 1 - o),
            transform,
            backgroundImage: backgroundUrl
              ? `url(${backgroundUrl})`
              : 'url("../../../images/logo 1.png")',
          }}
        ></CardBody>
        <CardBody
          style={{
            opacity,
            transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
          }}
        >
          <Title>{player.content.name}</Title>
          {/* <Description>{player.content.description}</Description> */}
        </CardBody>
      </Card>
      <Text>{player?.name}</Text>
    </Container>
  );
}
