import React, { useState } from "react";
import { Container, DropItem, Dropdown, Link } from "./styles";
import { useHistory } from "react-router-dom";

export default function NavLink({ dropdown, title, path, icon }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Container>
      <Link
        onClick={
          dropdown !== undefined
            ? () => setOpen(!open)
            : () => history.push(path)
        }
      >
        {icon}
        {"    "}
        {title}
      </Link>
      <Dropdown className={open && "show"}>
        {dropdown !== undefined &&
          dropdown.map(
            (item) =>
              !item?.hidden && (
                <DropItem onClick={() => history.push(item.path)}>
                  {item.icon}
                  <span>{item.title}</span>
                </DropItem>
              )
          )}
      </Dropdown>
    </Container>
  );
}
