import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  z-index: 2;
  min-height: 100vh;
  padding: 10px 0 10px 0;
  background-image: url("../../../images/01_bg.jpg");
  background-size: cover;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: min(70%, 800px);
  padding: 20px;
  text-align: center;
  z-index: 2;
`;
export const TextHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  z-index: 2;
  margin-top: 15px;
  z-index: 2;
`;
export const Title = styled.div`
  font-size: 3em;
  color: var(--white);
  margin-bottom: 20px;
  z-index: 2;
  font-family: var(--title-font);
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  color: var(--secondary);
  z-index: 2;
  margin-top: 20px;
  @media (max-width: 800px) {
    margin: 0 5px 0 5px;
    text-align: center;
  }
`;

export const Card = styled.div`
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  // background-image: url("../../../images/texture.png");
  background-position: center;
  -webkit-box-shadow: 5px 5px 20px 5px var(--dark);
  box-shadow: 5px 5px 20px 5px var(--dark);
  width: 15%;
  height: 200px;
  padding: 12px 24px 12px 24px;
  margin: 0 10px 0 10px;
  transition: all ease-in-out 2000;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    transform: translateY(-15px);
    opacity: 1;
  }

  @media (max-width: 600px) {
    width: 80%;
    margin: 20px;
    opacity: 1;
  }
`;
export const CardTitle = styled.div`
  /* margin-bottom: 1em; */
  text-align: center;
  font-size: var(--font-lg);
  z-index: 2;
  color: var(--white);
`;
export const CardDescription = styled.p`
  color: var(--white);
  font-size: var(--font-sm);
  z-index: 2;
  text-align: justify;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--sm);
  color: var(--white);
  width: 30%;
  text-align: center;
  margin: 20px 0 20px;
  p {
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
  z-index: 2;
`;

export const StartButton = styled(PrimaryButton)`
  margin-top: 1em;
  z-index: 2;
`;
export const CardButton = styled(PrimaryButton)`
  align-self: center;
  justify-self: flex-end;
  margin: unset;
  z-index: 2;
`;
export const HomeImage = styled.div`
  position: absolute;
  background-image: url("/images/vetor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 80vh;
  z-index: 1;
`;

export const DivAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 2;
`;
