import React, { useState } from "react";
import Admin from "../../../layout/Admin";
import FinishedCompetitions from "../../../components/Dashboard/FinishedCompetitions";

export default function Classificar() {
  return (
    <Admin>
      <FinishedCompetitions />
    </Admin>
  );
}
