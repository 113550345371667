import React from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import Loading from "../../ui/Loading";
import $ from "jquery";
import {
  Container,
  Title,
  Subtitle,
  CategoriesList,
  List,
  ListTitle,
  ListMessage,
  PreviousIcon,
  NextIcon,
  ButtonsList,
  FilterButton,
  FilterButton2,
  FilterButton3,
  SquaredTitle,
  DivTitle,
  DivButton,
  VetorImage,
  Select,
} from "./styles";
import { useState } from "react";
import FlipCard from "../../ui/FlipCard";

export default function Categories() {
  const { data } = useApi("/category/getAll");
  const [condition, setCondition] = useState(1);
  var compCounter = 0;
  var catCounter = 0;
  var fullCondition;

  const history = useHistory();
  const scrollBack = (id) => {
    let width = window.innerWidth > 600 ? $(`#${id}`).width() : 601;
    $(`#${id}`).animate({ scrollLeft: $(`#${id}`).scrollLeft() - width }, 1000);
  };
  const scrollNext = (id) => {
    let width = window.innerWidth > 600 ? $(`#${id}`).width() : 265;
    $(`#${id}`).animate({ scrollLeft: $(`#${id}`).scrollLeft() + width }, 1000);
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <Container>
      <DivTitle>
        <SquaredTitle />
        <Title>COMPETIÇÕES</Title>
      </DivTitle>
      <Subtitle>Aqui começa sua jornada para <br /> se tornar um master 5!</Subtitle>
      {window.innerWidth > 600 ? (
        <DivButton>
        <ButtonsList>
          <FilterButton onClick={() => setCondition(1)}>Abertas</FilterButton>
          <FilterButton2 onClick={() => setCondition(2)}>
            Em votação
          </FilterButton2>
          <FilterButton3 onClick={() => setCondition(3)}>
            Encerradas
          </FilterButton3>
        </ButtonsList>
        </DivButton>
      ) : (
        <Select onChange={(e) => setCondition(parseInt(e.target.value))}>
          <option value={1}>Abertas</option>
          <option value={2}>Iniciadas</option>
          <option value={3}>Finalizadas</option>
        </Select>
      )}
      {data.map((category, index) => {
        compCounter = 0;
        if (category.competition.length > 0) {
          return (
            <>
              {/* <ListTitle>{category.name}</ListTitle> */}
              <CategoriesList>
                <PreviousIcon onClick={() => scrollBack(category._id)} />
                <List id={category._id}>
                  {category.competition.length > 0 && (
                    <>
                      {category.competition.map((comp) => {
                        switch (condition) {
                          case 1:
                            fullCondition = comp.actualPhase === undefined;
                            break;
                          case 2:
                            fullCondition = comp.actualPhase < 4;
                            break;
                          case 3:
                            fullCondition = comp.actualPhase === 4;
                            break;
                          default:
                            break;
                        }
                        if (fullCondition) {
                          compCounter++;
                          return (
                            <FlipCard
                              name={comp.name}
                              numPlayers={comp.numPlayers}
                              description={comp.description}
                              path={`/competicoes/${comp._id}`}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  )}
                </List>
                <NextIcon onClick={() => scrollNext(category._id)} />
              </CategoriesList>
              
            </>
          );
        } else {
          catCounter++;
        }
      })}
      {data.length === catCounter && (
        <ListMessage style={{ textAlign: "center" }}>
          Nenhuma competição cadastrada
        </ListMessage>
      )}
    </Container>
  );
}
