import styled from "styled-components";

const PrimaryFileInput = styled.input`
  padding: 20px;

  border: 1px var(--secondary) dashed;
  border-radius: var(--radius-sm);
`;

export default PrimaryFileInput;
