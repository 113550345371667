import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Text,
  Terms,
  Logo,
  Copyrights,
  SocialMedia,
  Facebook,
  Twitter,
  Instagram,
} from "./styles";

export default function Footer() {
  const history = useHistory();
  return (
    <Container>
      <Text>
        Master 5 é um produto da empresa Irmãos Nabak, CNPJ 37.958.374/0001-24.
      </Text>
      <Text>Todos direitos reservados</Text>
      <Text className="terms" onClick={() => history.push("/termos-de-uso")}>
        <Terms>Termos de Uso e Política de Privacidade</Terms>
      </Text>
      <Logo src="/images/logotipo_inicio.png" alt="Logo" />
      <SocialMedia>
        <Facebook
          onClick={() =>
            window.open("https://www.facebook.com/master5brasil", "_blank")
          }
        />
        <Twitter
          onClick={() =>
            window.open("https://twitter.com/Master5Brasil", "_blank")
          }
        />
        <Instagram
          onClick={() =>
            window.open("https://www.instagram.com/master5brasil/", "_blank")
          }
        />
      </SocialMedia>
      <Copyrights href="https://mindconsulting.com.br/">
        Orgulhosamente Desenvolvido por Mind Consulting
      </Copyrights>
    </Container>
  );
}
