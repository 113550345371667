import styled from "styled-components";
import PrimaryInput from "../ui/PrimaryInput";
import { MdClose } from "react-icons/md";
import PrimaryButton from "../ui/PrimaryButton";

export const Container = styled.div`
  position: fixed;
  top: 30px;
  /* left: 25%; */
  width: min(100%, 400px);
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: var(--primary-600);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../images/texture.png");
  background-position: center;
  border-radius: var(--radius-sm);
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  font-family: var(--title-font);
  color: var(--white);
  margin-bottom: 10px;
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: var(--font-sm);
  font-family: var(--button-font);
  color: var(--secondary);
  margin-bottom: 10px;
  text-align: center;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
`;
export const Input = styled(PrimaryInput)`
  color: var(--white);
  margin-bottom: 10px;
  &&::placeholder {
    color: var(--white);
  }
`;
export const CloseIcon = styled(MdClose)`
  position: absolute;
  cursor: pointer;
  color: var(--white);

  top: 10px;
  right: 15px;
  width: 20px;
  height: 20px;
`;

export const SubmitButton = styled(PrimaryButton)`
  margin-top: 10px;
`;
