import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";

import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  // background-image: url("../../../images/01_bg.jpg");
  background-size: cover;
  background-position: bottom;
`;

export const Text = styled.div`
  display: flex;
  font-size: 13px;
  color: var(--white);
  margin: 5px 0 5px 0;

  &.terms {
    color: var(--secondary);
  }
`;
export const Terms = styled.div`
  margin: 0 4px 0 4px;
  cursor: pointer;
`;
export const Logo = styled.img`
  margin-top: 10px;
  height: 70px;
  width: auto;
`;

export const Copyrights = styled.a`
  justify-self: flex-end;
  margin-bottom: 7px;
  font-size: 13px;
  color: var(--white);
`;

export const SocialMedia = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-around;
  align-self: center;
  margin: 20px;
`;

const iconCss = css`
  width: 32px;
  height: 32px;
  margin: 0 10px 0 10px;
  cursor: pointer;
  svg {
    color: var(--white);
  }
`;

export const Facebook = styled(FaFacebookSquare)`
  ${iconCss}
  fill: #3b5998;
`;
export const Twitter = styled(FaTwitterSquare)`
  ${iconCss}
  fill: #00acee;
`;
export const Instagram = styled(FaInstagramSquare)`
  ${iconCss}
  fill: #3f729b;
`;
