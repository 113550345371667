import React, { useState } from "react";
import {
  Container,
  Body,
  Title,
  Input,
  SubmitButton,
  CloseIcon,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../services/api";

export default function PasswordModal({ setOpen }) {
  const [email, setEmail] = useState(null);
  const [valid, setValid] = useState(false);

  const handleEmail = async () => {
    try {
      const { data } = await api.get("/users/getByEmail/" + email);
      if (data === "") {
        toast.error("E-mail não encontrado! Cadastre-se já!");
      } else {
        setValid(true);
      }
    } catch (error) {
      toast.error("E-mail não encontrado! Cadastre-se já!");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const { data } = await api.post("/users/passRecovery", {
          email,
        });
        if (data.success) {
          toast.success("Um email para alterar sua senha foi enviado!");
        } else {
          toast.error(
            "Oops, houve um erro no nosso servidor, tente novamente!"
          );
        }
      } catch (error) {
        toast.error("Oops, houve um erro no nosso servidor, tente novamente!");
      }
    }
  };
  return (
    <Container>
      <Body>
        <CloseIcon onClick={() => setOpen(false)} />
        <Title>Esqueceu sua senha?</Title>
        <Input
          type="email"
          placeholder="Insira seu e-mail"
          onBlur={handleEmail}
          onChange={(e) => {
            setEmail(e.target.value);
            setValid(false);
          }}
        />
        <SubmitButton disabled={!valid} onClick={handleSubmit}>
          Recuperar!
        </SubmitButton>
      </Body>
    </Container>
  );
}
