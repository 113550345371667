import styled from "styled-components";
import PrimaryButton from "../../ui/PrimaryButton";
import PrimarySelect from "../../ui/PrimarySelect";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70%;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-lg);
  color: var(--primary);
`;
export const Body = styled.div``;
export const Select = styled(PrimarySelect)`
  margin-top: 15px;
  width: 40%;
  color: var(--primary);
  option {
    color: var(--primary);
  }
`;
export const ConfirmButton = styled(PrimaryButton)``;
