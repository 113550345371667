import React from "react";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import api from "../../../services/api";
import Loading from "../../ui/Loading";
import { Container, Title, TextMuted, Card, Name, Phase, Row } from "./styles";

export default function StartCompetition() {
  const { data } = useApi("/competition/getAll");

  const handleStart = async (id) => {
    try {
      const { data } = await api.get(`competition/start/${id}`);
      if (data.success) {
        toast.success("Competição iniciada com sucesso!");
      } else {
        toast.error("Ooops, hou um erro ao iniciar a competição");
      }
    } catch (error) {
      toast.error("Ooops, hou um erro ao iniciar a competição");
    }
  };

  if (!data) {
    return <Loading />;
  }
  return (
    <Container>
      <Title>Inicie uma competição!</Title>
      <TextMuted>
        Clique em uma competição para dar início aos períodos de votação!
      </TextMuted>
      <Row>
        {data.map((item) => {
          if (
            item.dateEnd < Date.now() / 1000 &&
            item.actualPhase === undefined
          ) {
            return (
              <Card onClick={() => handleStart(item._id)}>
                <Name>{item.name}</Name>
              </Card>
            );
          }
        })}
      </Row>
    </Container>
  );
}
