import React, { useEffect, useRef } from "react";
import { Container, StartButton, HomeImage, DivHome, Title } from "./styles";

import { useHistory } from "react-router-dom";

export default function HomeSection() {
  const history = useHistory();
  return (
    <Container>
      <DivHome>
        <Title>
          Participe de competições online <br />
          e concorra a premiações em dinheiro.
          <br />
          Comece hoje mesmo sua jornada <br />
          para se tornar um master 5!
        </Title>
        <StartButton onClick={() => history.push("/competicoes")}>
          Competições Abertas
        </StartButton>
      </DivHome>
      <HomeImage />
    </Container>
  );
}
