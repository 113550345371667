import React, { useRef } from "react";
// import { Checkbox } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";

import {
  Container,
  RankingRow,
  Rank,
  Name,
  Title,
  Votes,
  Checkbox,
} from "./styles";

export default function RankingList({
  group,
  approved,
  actualPhase,
  max,
  setMaster5Button,
  competition_id,
}) {
  // Handle Checkbox changes
  function handleChange(value, index) {
    // index = Posição do classificado -1
    if (value) {
      if (approved.length < max) {
        approved.push(index);
        if (approved.length === max && actualPhase === 4 && setMaster5Button) {
          setMaster5Button(true);
        } else {
          setMaster5Button(false);
        }
      } else {
        toast.error("Limite Máximo atingido!");
      }
    } else if (approved.includes(index)) {
      approved.splice(approved.indexOf(index), 1);
      setMaster5Button && setMaster5Button(false);
    }
  }
  function autoApprove(value) {
    let i = 0;
    let j = max - 1;
    if (value) {
      while (i < max) {
        approved.push(`${i}`);
        $(`#checkbox_${i}`).prop("checked", true);
        i++;
      }
      setMaster5Button && setMaster5Button(true);
    } else {
      approved = [];
      while (j >= 0) {
        $(`#checkbox_${j}`).prop("checked", false);
        j--;
      }
      setMaster5Button && setMaster5Button(false);
    }
  }

  return (
    <Container>
      <ToastContainer />
      <RankingRow>
        <Rank>
          <strong>Classificar</strong>
          <Checkbox
            type="checkbox"
            key="checkbox_all"
            onChange={(e) => autoApprove(e.target.checked)}
          />
        </Rank>
        <Name>
          <strong>Competidor</strong>
        </Name>
        <Title>
          <strong>Apresentação</strong>
        </Title>
        <Votes>
          <strong>Nº de Votos</strong>
        </Votes>
      </RankingRow>
      {group.map((player, index) => (
        <RankingRow key={player.user_id}>
          <Rank>
            {index + 1 + "º "}{" "}
            <Checkbox
              type="checkbox"
              id={"checkbox_" + index}
              key={"checkbox_" + player.user_id}
              value={index.toString()}
              defaultChecked={
                approved !== null && approved.includes(index.toString())
              }
              onChange={(e) => handleChange(e.target.checked, e.target.value)}
            />
          </Rank>
          <Name>{player.name}</Name>
          <Title
            onClick={() => {
              window.open(
                `/competicao/${competition_id}-${player.user_id}`,
                "_blank"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            Abrir Apresentação
          </Title>
          <Votes>{player.votes}</Votes>
        </RankingRow>
      ))}
    </Container>
  );
}
