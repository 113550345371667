import React from "react";
import Categories from "../../../components/Competicoes/Categories";
import Main from "../../../layout/Main";
import { Container, VetorImage } from "./styles";
export default function UserCategories() {
  return (
    <Main>
      <Container>
        <VetorImage />
        <Categories />
      </Container>
    </Main>
  );
}
