import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import { Transcode } from "../../../serverside";
import { useSelector } from "react-redux";
import api from "../../../services/api";
import Loading from "../../ui/Loading";
import {
  Container,
  Title,
  Subtitle,
  HorizontalLine,
  TextMd,
  Body,
  Input,
  TextArea,
  FileInput,
  SubmitButton,
} from "./styles";
import PaymentModal from "../../PaymentModal";

export default function JoinCompetition() {
  const [newData, setNewData] = useState(null);
  const [modal, setModal] = useState(false);
  const [paid, setPaid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [joined, setJoined] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { data } = useApi(`/competition/get/${id}`);

  useEffect(() => {
    isJoined();
    if (joined !== null) {
      if (joined === true) {
        toast.error("Você já está participando desta competição!");
        history.push(`/competicoes/${id}`);
      } else {
        setJoined(false);
        setLoading(false);
      }
    }
  }, [joined]);

  const isJoined = async () => {
    const {
      data,
    } = await api.post(
      `/competition/isJoined/${localStorage.getItem("@Master5/id")}`,
      { competition_id: id }
    );
    setJoined(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const file = e.target?.files;
    if (file) {
      setNewData((prevState) => ({
        ...prevState,
        [name]: file[0],
      }));
    } else {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    setDisabled(true);
    if (newData?.name && newData?.description && newData?.[data.content]) {
      let formData = new FormData();
      formData.append("name", localStorage.getItem("@Master5/name"));
      formData.append("email", localStorage.getItem("@Master5/email"));
      formData.append("region", localStorage.getItem("@Master5/region"));
      formData.append("contentName", newData.name);
      formData.append("description", newData.description);
      formData.append("competition_id", id);
      if (newData?.image) {
        formData.append(
          "image",
          new File([newData.image], Date.now() + ".jpg", {
            type: "image/jpg",
          })
        );
      }
      if (newData?.doc) {
        formData.append(
          "doc",
          new File([newData.doc], Date.now() + ".pdf", {
            type: "application/pdf",
          })
        );
      }
      if (newData?.video) {
        formData.append("video", newData.video);
      }
      try {
        const response = await api.post(
          `competition/join/${localStorage.getItem("@Master5/id")}`,
          formData
        );
        if (response.data.success) {
          if (newData?.video) {
            toast.info("Aguarde enquanto seu vídeo está sendo processado...", {
              autoClose: false,
            });
            const timer = setInterval(async () => {
              const resp = await api.post(
                `competition/isUploaded/${localStorage.getItem("@Master5/id")}`,
                { competition_id: id }
              );

              if (resp.data[0]?.players[0]?.content?.path) {
                toast.dismiss();

                toast.success("Agora você está participando desta competição!");
                toast.info(
                  "Seu vídeo levará aproximadamente 30 min para ser aprovado e exibido!"
                );
                history.push(
                  `/competicao/${id}-${localStorage.getItem("@Master5/id")}`
                );

                clearInterval(timer);
              }
            }, 10000);
          } else {
            setDisabled(false);
            toast.success("Agora você está participando desta competição!");
            history.push(
              `/competicao/${id}-${localStorage.getItem("@Master5/id")}`
            );
          }
        }
      } catch (error) {
        setDisabled(false);

        toast.error("Oops, houve algum erro ao cadastrar");
      }
    } else {
      setDisabled(false);

      toast.error(
        "Por favor, insira todos os dados para participar da competição!"
      );
    }
  };

  if (!data || loading) {
    return <Loading />;
  }
  if (data?.actualPhase) {
    toast.error("Esta competição já teve inicio!");
    history.push(`/competicoes/${id}`);
  }
  return (
    <>
      <Container>
        <Title>{data.name}</Title>
        <Subtitle>{data.description}</Subtitle>
        <HorizontalLine />
        <TextMd>Venha se tornar um Master 5!</TextMd>
        <Subtitle>
          Coloque o título e a descrição de sua apresentação!
          <br />
          Na descrição coloque seu @ do instagram para quem quiser conhecer mais
          do seu trabalho!
          <br />
          Para se inscrever é necessário o pagamento de uma pequena taxa
          utilizada para pagar as despesas da competição. Lembre-se de se
          divertir e pedir muitos votos!
        </Subtitle>
        <Body>
          <Input
            onChange={(e) => handleChange(e)}
            name="name"
            placeholder="Insira o título de sua apresentação."
          />
          <TextArea
            onChange={(e) => handleChange(e)}
            name="description"
            placeholder="Insira uma descrição breve de sua apresentação."
          />
          {data.content === "doc" && (
            <FileInput
              onChange={(e) => handleChange(e)}
              type="file"
              name="doc"
              accept=".pdf"
            />
          )}
          {data.content === "image" && (
            <FileInput
              onChange={(e) => handleChange(e)}
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png"
            />
          )}
          {data.content === "video" && (
            <FileInput
              onChange={(e) => handleChange(e)}
              type="file"
              name="video"
              accept=".mp4,.mov,.wmv,.avi,.flv"
            />
          )}
          <SubmitButton
            onClick={() => {
              let i = 0;
              data.paidPhases.map((phase) => {
                if (phase === "start") {
                  i++;
                }
              });
              if (i === 0 || paid) {
                handleSubmit();
              } else {
                setModal(!modal);
              }
            }}
            disabled={disabled}
          >
            Participar!
          </SubmitButton>
        </Body>
      </Container>
      {modal && (
        <PaymentModal
          setModal={setModal}
          handleSubmit={handleSubmit}
          transactionAmount={data?.price}
          setPaid={setPaid}
        />
      )}
    </>
  );
}
