import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import {
  Container,
  Header,
  Title,
  Subtitle,
  Body,
  Input,
  Backdrop,
  CloseIcon,
  SubmitButton,
} from "./styles";

export default function PaymentModal({
  setModal,
  handleSubmit,
  transactionAmount,
  setPaid,
}) {
  const { id } = useParams();
  const [cardData, setCardData] = useState({
    email: localStorage.getItem("@Master5/email"),
    competition_id: id,
    user_id: localStorage.getItem("@Master5/id"),
    phase: "start",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    window.Mercadopago.setPublishableKey(
      "APP_USR-3d9508dd-dedc-4bf2-b955-96e47a7c98d8"
    );
  }, []);

  async function guessPaymentMethod() {
    if (cardData?.cardNumber) {
      let bin = cardData.cardNumber.substring(0, 6);
      await window.Mercadopago.getPaymentMethod(
        {
          bin: bin,
        },
        (status, response) => {
          if (status === 200) {
            let paymentMethod = response[0];
            setCardData((prevState) => ({
              ...prevState,
              paymentMethodId: paymentMethod.id,
            }));
          } else {
            toast.error("Dados de cartão inválidos");
            setError((prevState) => ({
              ...prevState,
              cardError: true,
            }));
          }
        }
      );
    }
  }

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name === "date") {
      let data = value.split("/");
      setCardData((prevState) => ({
        ...prevState,
        cardExpirationMonth: data[0],
        cardExpirationYear: data[1],
      }));
    } else if (name === "cardNumber") {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value.replaceAll(" ", ""),
      }));
    } else {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePayment = async () => {
    setDisabled(true);

    let $form = document.getElementById("paymentForm");
    window.Mercadopago.createToken($form, async (status, response) => {
      if (status === 200 || status === 201) {
        try {
          const { data } = await api.post("payment/checkout", {
            ...cardData,
            transactionAmount,
            token: response.id,
          });
          if (data.success) {
            toast.success("Pagamento processado com sucesso!");
            setDisabled(false);

            setPaid(true);
            handleSubmit();
          } else {
            setDisabled(false);

            toast.error("Houve um erro ao processar seu pagamento");
          }
        } catch (error) {
          setDisabled(false);

          toast.error("Oops, seu cartão foi recusado");
        }
      } else {
        setDisabled(false);

        toast.error("Por favor, verifique seus dados!");
      }
    });
  };

  return (
    <>
      <Backdrop />
      <Container>
        <CloseIcon onClick={() => setModal(false)} />
        <Header>
          <Title>Taxa de inscricao!</Title>
          <Subtitle>
            Taxa para as despesas da competição:{" "}
            {parseFloat(transactionAmount).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}{" "}
          </Subtitle>
        </Header>
        <Body id="paymentForm">
          <Input
            placeholder="Nome como está no cartão"
            onChange={(e) => handleChange(e)}
            name="cardholderName"
            id="cardholderName"
            data-checkout="cardholderName"
          />
          <NumberFormat
            customInput={Input}
            format={"###.###.###-##"}
            name="docNumber"
            id="docNumber"
            data-checkout="docNumber"
            placeholder="CPF"
            onChange={(e) => handleChange(e)}
          />
          <NumberFormat
            customInput={Input}
            allowNegative={false}
            name="cardNumber"
            id="cardNumber"
            format={"#### #### #### ####"}
            data-checkout="cardNumber"
            placeholder="Número do cartão"
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={guessPaymentMethod}
          />
          <NumberFormat
            customInput={Input}
            format={"##/##"}
            name="date"
            placeholder="Validade do cartão"
            onChange={(e) => handleChange(e)}
          />
          <NumberFormat
            customInput={Input}
            name="securityCode"
            id="securityCode"
            data-checkout="securityCode"
            format={"###"}
            placeholder="CVV"
            onChange={(e) => handleChange(e)}
          />
          <input
            type="text"
            value={cardData?.cardExpirationMonth}
            id="cardExpirationMonth"
            name="cardExpirationMonth"
            data-checkout="cardExpirationMonth"
            hidden
          />
          <input
            type="text"
            value={cardData?.cardExpirationYear}
            id="cardExpirationYear"
            name="cardExpirationYear"
            data-checkout="cardExpirationYear"
            hidden
          />
          <input
            hidden
            id="issuer"
            name="issuer"
            data-checkout="issuer"
            value={cardData?.issuer_id}
          />
          <input
            hidden
            id="docType"
            name="docType"
            data-checkout="docType"
            value={"CPF"}
          />

          <SubmitButton onClick={handlePayment} disabled={disabled}>
            Pagar e Participar
          </SubmitButton>
        </Body>
      </Container>
    </>
  );
}
