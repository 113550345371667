import ReactPlayer from "react-player";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../services/api";
import Loading from "../../ui/Loading";
import {
  Container,
  Title,
  Subtitle,
  HorizontalLine,
  Button,
  VoteContainer,
  Image,
  DocumentHolder,
  PageList,
  PreviousIcon,
  NextIcon,
} from "./styles";
import RoundedImage from "../../ui/RoundedImage";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { Page } from "react-pdf";

export default function ViewCompetition() {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const recaptchaRef = useRef(null);

  const { id } = useParams();
  let idArray = id.split("-");
  const competition_id = idArray[0];
  const user_id = idArray[1];
  const getCompetition = async () => {
    try {
      const { data } = await api.post(
        `/competition/singleCompetition/${user_id}`,
        {
          competition_id: competition_id,
        }
      );
      setData(...data);
    } catch (error) {}
  };

  const getPlayer = async () => {
    try {
      const { data } = await api.get(`/users/get/${user_id}`);
      setUser(data);
    } catch (error) {}
  };
  const handlePage = (action) => {
    switch (action) {
      case "add":
        if (pageNumber + 1 <= numPages) {
          let page = pageNumber + 1;
          setPageNumber(page);
        }
        break;
      case "remove":
        if (pageNumber - 1 >= 1) {
          let page = pageNumber - 1;
          setPageNumber(page);
        }
        break;
      default:
        break;
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    getCompetition();
    getPlayer();
  }, []);

  const handleVote = async () => {
    recaptchaRef.current.reset();
    setRecaptcha(null);
    if (localStorage.getItem("@Master5/id")) {
      try {
        const { data } = await api.post(
          `/voting/vote/${localStorage.getItem("@Master5/id")}`,
          {
            competition_id,
            user_id,
          }
        );
        if (data.vote && data.addToVoting && data.userVote) {
          toast.success("Voto computado com sucesso!");
        } else {
          toast.error(
            "Oops, houve um erro na sua solicitação, por favor tente novamente!"
          );
        }
      } catch (error) {
        toast.error(
          "Oops, houve um erro na sua solicitação, por favor tente novamente!"
        );
      }
    } else {
      toast.error("Você precisa estar logado para votar!");
    }
  };

  if (!data || !user) {
    return <Loading />;
  }
  return (
    <Container>
      <RoundedImage src={user?.imgPath} />
      <Title>{data.players[0].content.name}</Title>
      <Subtitle>{data.players[0].content.description}</Subtitle>
      {data.content === "doc" && (
        <DocumentHolder>
          <Document
            file={`${api.defaults.baseURL}${data.players[0].content.path}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              width={window.innerWidth < 620 && window.innerWidth}
              pageNumber={pageNumber}
              customTextRenderer={({ str, itemIndex }) => {
                return null;
              }}
            />
          </Document>

          <PageList>
            <PreviousIcon
              onClick={() => {
                handlePage("remove");
              }}
            />
            <span>
              Página {pageNumber} de {numPages}
            </span>
            <NextIcon
              onClick={() => {
                handlePage("add");
              }}
            />
          </PageList>
        </DocumentHolder>
      )}
      {data.content === "image" && (
        <Image src={`${api.defaults.baseURL}${data.players[0].content.path}`} />
      )}
      {data.content === "video" && (
        <ReactPlayer
          url={`https://vimeo.com${data.players[0].content.path.replace(
            "/videos",
            ""
          )}`}
          controls
        />
      )}
      <HorizontalLine />

      {data?.actualPhase || data?.actualPhase <= 4 ? (
        <VoteContainer>
          <ReCAPTCHA
            ref={recaptchaRef}
            theme="dark"
            type="image"
            style={{ margin: 10 }}
            sitekey="6Lc-zLEZAAAAAGnuqw9AdejtOqeYMofVVRczO3K7"
            onChange={(token) => {
              setRecaptcha(token);
            }}
            onExpired={() => {
              setRecaptcha(null);
            }}
            onErrored={() => {
              setRecaptcha(null);
            }}
          />
          <Button onClick={handleVote} disabled={!recaptcha}>
            Votar!
          </Button>
        </VoteContainer>
      ) : (
        <Title>A votação ainda não iniciou!</Title>
      )}
    </Container>
  );
}
