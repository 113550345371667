import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Main from "../../layout/Main";
import api from "../../services/api";
import {
  Container,
  Form,
  TokenInput,
  Title,
  Subtitle,
  Input,
  Button,
} from "./styles";

export default function RecuperarSenha() {
  const [token, setToken] = useState(null);
  const [rightToken, setRightToken] = useState(false);
  const [data, setData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const getToken = async () => {
      api
        .get(`users/getToken/${token}`)
        .then(({ data }) => {
          setRightToken(data.success);
          if (data.success === false) {
            toast.error("Token de confirmação inválido");
          }
        })
        .catch((err) => {
          toast.error("Oops, houve algum erro no servidor!");
        });
    };
    if (token && token.length === 6) {
      getToken();
    }
  }, [token]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    if (data.password === data.confirmPassword) {
      api
        .post(`users/update/${token}`, { password: data.password })
        .then(({ data }) => {
          if (data.success) {
            toast.success("Senha alterada com sucesso!");
            history.push("/");
          } else {
            toast.error("Oops, houve algum erro no servidor!");
          }
        })
        .catch((err) => {
          toast.error("Oops, houve algum erro no servidor!");
        });
    } else {
      toast.error("As senhas precisam ser iguais!");
    }
  };

  return (
    <Main>
      <Container>
        <Form>
          <Title>Alteracao de Senha</Title>
          {!rightToken && (
            <>
              <Subtitle>
                Insira o código recebido em seu email abaixo para alterar sua
                senha.
              </Subtitle>
              <TokenInput
                maxLength={6}
                placeholder={"------"}
                onChange={(e) => setToken(e.target.value.toUpperCase())}
              />
            </>
          )}
          {rightToken && (
            <>
              <Subtitle>
                Insira a nova senha e confirme-a para altera-la.
              </Subtitle>
              <Input
                type="password"
                placeholder="Nova senha"
                name="password"
                onChange={(e) => handleChange(e)}
              />
              <Input
                type="password"
                placeholder="Confirmar senha"
                name="confirmPassword"
                onChange={(e) => handleChange(e)}
              />
              <Button onClick={handleSubmit}>ALTERAR SENHA</Button>
            </>
          )}
        </Form>
      </Container>
    </Main>
  );
}
