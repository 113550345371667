import React, { useState, useEffect } from "react";

import RankingList from "../../../components/Dashboard/RankingList";
import Admin from "../../../layout/Admin";
import useApi from "../../../hooks/useApi";

import api from "../../../services/api";

import { toast } from "react-toastify";

import { Title, Subtitle, SubmitButton } from "./styles";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/ui/Loading";
import Tabs from "../../../components/ui/Tabs";

export default function Ranking({ match }) {
  const [component, setComponent] = useState(1);
  const [approved, setApproved] = useState([[], [], [], [], []]);
  const [master5Button, setMaster5Button] = useState(false);
  const { data } = useApi("tournament/get/" + match.params.competition_id);

  const history = useHistory();

  let max;
  if (!data) {
    return <Loading />;
  } else {
    max =
      data?.actualPhase === 0
        ? 25
        : data?.actualPhase === 1
        ? 10
        : data?.actualPhase === 2
        ? 1
        : data?.actualPhase === 3 && 1;
  }

  const handleSubmit = async () => {
    let winners = [];
    let mailList = [];
    for (let i = 0; i <= 4; i++) {
      approved[i].map((player) => {
        winners.push(data.phase[data.actualPhase][0][i][parseInt(player)]);
        mailList.push(
          data.phase[data.actualPhase][0][i][parseInt(player)]?.email
        );
      });
    }
    try {
      const response = await api.post("/tournament/nextPhase/" + data._id, {
        winners,
        actualPhase: data.actualPhase,
        paidPhases: data?.paidPhases,
        mailList,
        price: data?.price,
        competition_id: data._id,
      });
      if (response.data.success) {
        toast.success(response.data.message);
        history.push("/dashboard/classificar");
      }
    } catch (error) {
      toast.error(
        "Oops, houve um erro ao cadastrar! Verifique os candidatos aprovados!"
      );
    }
  };

  const handleWinner = async () => {
    let winner = data.phase[data.actualPhase][0][parseInt(approved[0][0])];
    try {
      const response = await api.post("/tournament/winner/" + data._id, {
        master5: winner,
      });
      toast.success(`${winner.playerName} é o mais novo Master 5!`);
      history.push("/dashboard/classificar");
    } catch (error) {
      toast.error("Oops, houve um erro em nosso servidor...");
    }
  };

  return (
    <Admin>
      <Title>Fase {data.actualPhase + 1}</Title>
      <Subtitle>
        Nesta fase, somente {max} participantes serão aprovados por grupo!
      </Subtitle>
      {approved[0]?.length === max &&
        approved[1]?.length === max &&
        approved[2]?.length === max &&
        approved[3]?.length === max &&
        approved[4]?.length === max && (
          <SubmitButton onClick={handleSubmit}>Concluir</SubmitButton>
        )}
      {master5Button && (
        <SubmitButton onClick={handleWinner}>Novo Master 5!</SubmitButton>
      )}
      {data.actualPhase === 3 ? (
        <RankingList
          group={data.phase[data.actualPhase][0]}
          approved={approved[0]}
          actualPhase={data.actualPhase}
          setMaster5Button={setMaster5Button}
          max={max}
        />
      ) : (
        <>
          <Tabs
            setComponent={setComponent}
            tabs={[
              {
                name: "Grupo 1",
              },
              {
                name: "Grupo 2",
              },
              {
                name: "Grupo 3",
              },
              {
                name: "Grupo 4",
              },
              {
                name: "Grupo 5",
              },
            ]}
          />

          {component === 1 && (
            <RankingList
              group={data.phase[data.actualPhase][0][0]}
              approved={approved[0]}
              actualPhase={data.actualPhase}
              max={max}
              competition_id={data._id}
            />
          )}
          {component === 2 && (
            <RankingList
              group={data.phase[data.actualPhase][0][1]}
              approved={approved[1]}
              actualPhase={data.actualPhase}
              max={max}
              competition_id={data._id}
            />
          )}
          {component === 3 && (
            <RankingList
              group={data.phase[data.actualPhase][0][2]}
              approved={approved[2]}
              actualPhase={data.actualPhase}
              max={max}
              competition_id={data._id}
            />
          )}
          {component === 4 && (
            <RankingList
              group={data.phase[data.actualPhase][0][3]}
              approved={approved[3]}
              actualPhase={data.actualPhase}
              max={max}
              competition_id={data._id}
            />
          )}
          {component === 5 && (
            <RankingList
              group={data.phase[data.actualPhase][0][4]}
              approved={approved[4]}
              groupNumber={4}
              actualPhase={data.actualPhase}
              max={max}
              competition_id={data._id}
            />
          )}
        </>
      )}
    </Admin>
  );
}
