import styled from "styled-components";

const PrimaryInput = styled.input`
border: unset;
border-bottom: 2px solid #fff;
background: transparent;
margin-bottom: 2em;
padding: 10px;
color: var(--white);
font-size: var(--font-md);
width: calc(100% - 20px);
border: 2px solid #fff;
border-radius: var(--radius-sm);
color: #fff;
width: calc(100% - 20px);
border: 2px solid #ff;
border-radius: var(--radius-sm);
color: #fff;
  }

  &&:hover {
    border-bottom: 1px solid var(--secondary-100);
  }

  &&::placeholder {
    color: var(--white);
  }
`;
export default PrimaryInput;
