import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 2;
  background: var(--primary-700);
  @media (max-width: 800px) {
    margin-top: 80px;
  }
`;
export const Background = styled.div`
  position: relative;
  background-image: url(/images/01_bg.jpg);
  background-size: contain;
  background-repeat: repeat;
  background-position: inherit;
  z-index: 2;
`;

export const Vetor = styled.div`
  position: relative;
  background-image: url(/images/vetor.png);
  background-size: contain;
  background-repeat: repeat;
  background-position: left;
  margin-top: 80px;
  z-index: 2;
`;
