import React, { useState, useEffect } from "react";
import routing from "../../../Routes/routing";
import {
  Container,
  LogoHolder,
  Logo,
  Nav,
  Link,
  LoginIcon,
  LogoutIcon,
  MenuIcon,
} from "./styles";
import NavLink from "../NavLink";
import SignUpModal from "../../Login/SignUpModal";
// import LoginModal from "../../Login/LoginModal";
// import PasswordModal from "../../Login/PasswordModal";
import { logout } from "../../../services/auth";
import { useHistory } from "react-router-dom";
import Login from "../../../pages/Login";

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalSignUp, setModalSignUp] = useState(false);
  const history = useHistory();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <>
      <Container className={scrolled && "scrolled"}>
        <LogoHolder>
          <MenuIcon onClick={() => setMobileMenu(!mobileMenu)} />
          <Logo
            onClick={() => history.push("/")}
            src="/images/logotipo_inicio.png"
            alt="Logo"
          />
        </LogoHolder>
        <Nav className={mobileMenu && "open"}>
          {routing.public.map(
            (route) =>
              !route?.hidden && (
                <NavLink
                  title={route.title}
                  dropdown={route?.dropdown}
                  path={route.path}
                />
              )
          )}
          <Link
            onClick={() =>
              setModalSignUp({ ...modalSignUp, SignUpModal: !modal.SignUp })
            }
          >
            Cadastre-se
          </Link>
          {localStorage.getItem("@Master5/id") ? (
            <Link
              onClick={() => {
                logout();
                window.location.reload();
              }}
            >
              {"Logout".toUpperCase()}
            </Link>
          ) : (
            <Link onClick={() => setModal({ ...modal, login: !modal.login })}>
              {"Login".toUpperCase()}
            </Link>
          )}
        </Nav>
      </Container>
      {modal && <Login setOpen={setModal} />}
      {modalSignUp && <SignUpModal setOpen={setModalSignUp} />}
    </>
  );
}
