import React from "react";
import StartCompetition from "../../../components/Dashboard/StartCompetition";
import Admin from "../../../layout/Admin";

export default function IniciarCompeticao() {
  return (
    <Admin>
      <StartCompetition />
    </Admin>
  );
}
